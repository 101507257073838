import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ShoppingCartIcon from './ShoppingCartIcon';
import ShoppingBagIcon from './ShoppingBagIcon';
import ShoppingCartNumber from './ShoppingCartNumber';

const iconMap = {
  cart: ShoppingCartIcon,
  bag: ShoppingBagIcon,
};

class ShoppingCart extends Component {
  render() {
    const { cartItemsQuantity, onClick } = this.props;
    const appCustomizations = this.props.appConfiguration.personalization;
    const color = appCustomizations.header.styles.color;
    const IconComponent = iconMap[appCustomizations.shoppingCart.iconName];
    return (
      <div className="adroll-rollie-web-cart" onClick={onClick}>
        <IconComponent style={{ fill: color }} />

        <span className="adroll-rollie-cart-label" style={{ color }}>
          {appCustomizations.desktopShoppingCart.text}
        </span>

        <ShoppingCartNumber style={{}} cartItemsQuantity={cartItemsQuantity} />
      </div>
    );
  }
}

ShoppingCart.propTypes = {
  appConfiguration: PropTypes.object.isRequired,
  cartItemsQuantity: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default ShoppingCart;
