import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShoppingBagIcon extends Component {
  render() {
    const style = {
      ...this.props.style,
      width: 18,
    };
    return (
      <svg className={'adroll-rollie-cart-icon'} version={'1.1'} x={'0px'} y={'0px'} style={style}>
        <g>
          <path d="M19.6093984,16.5714269 L20.0000238,20.0647346 C20.0223453,20.2656277 19.9553809,20.4665208 19.8214522,20.622771 C19.6875235,20.7678604 19.4866304,20.8571463 19.2857373,20.8571463 L0.714286566,20.8571463 C0.513393469,20.8571463 0.312500373,20.7678604 0.178571641,20.622771 C0.0446429104,20.4665208 -0.0223214552,20.2656277 -1.38777878e-17,20.0647346 L0.390625466,16.5714269 L19.6093984,16.5714269 Z M18.5714507,7.20757642 L19.5312733,15.8571403 L0.468750559,15.8571403 L1.42857313,7.20757642 C1.47321604,6.85043314 1.77455569,6.57141495 2.1428597,6.57141495 L5.00000596,6.57141495 L5.00000596,7.99998808 C5.00000596,8.79239974 5.63616743,9.42856121 6.42857909,9.42856121 C7.22099075,9.42856121 7.85715222,8.79239974 7.85715222,7.99998808 L7.85715222,6.57141495 L12.1428716,6.57141495 L12.1428716,7.99998808 C12.1428716,8.79239974 12.7790331,9.42856121 13.5714447,9.42856121 C14.3638564,9.42856121 15.0000179,8.79239974 15.0000179,7.99998808 L15.0000179,6.57141495 L17.8571641,6.57141495 C18.2254682,6.57141495 18.5268078,6.85043314 18.5714507,7.20757642 Z M14.2857313,5.14284182 L14.2857313,7.99998808 C14.2857313,8.39061354 13.9620702,8.71427464 13.5714447,8.71427464 C13.1808193,8.71427464 12.8571582,8.39061354 12.8571582,7.99998808 L12.8571582,5.14284182 C12.8571582,3.56917923 11.5736745,2.28569555 10.0000119,2.28569555 C8.42634933,2.28569555 7.14286566,3.56917923 7.14286566,5.14284182 L7.14286566,7.99998808 C7.14286566,8.39061354 6.81920456,8.71427464 6.42857909,8.71427464 C6.03795363,8.71427464 5.71429253,8.39061354 5.71429253,7.99998808 L5.71429253,5.14284182 C5.71429253,2.77676757 7.63393767,0.857122421 10.0000119,0.857122421 C12.3660862,0.857122421 14.2857313,2.77676757 14.2857313,5.14284182 Z" />
        </g>
      </svg>
    );
  }
}

ShoppingBagIcon.propTypes = {
  style: PropTypes.object.isRequired,
};

export default ShoppingBagIcon;
