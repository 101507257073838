import { readFromSession, writeToSession } from './storage';
import { APP_STATE_KEY } from './constants';
import { getPreviewModeType, getPreviewModePreferences } from './previewMode';

const getAppState = () => {
  return readFromSession(APP_STATE_KEY);
};

const updateAppState = appStateChanges => {
  const appState = readFromSession(APP_STATE_KEY);
  writeToSession(APP_STATE_KEY, {
    ...appState,
    ...appStateChanges,
  });
};

export const Preferences = {
  getViewState() {
    return getPreviewModeType() ? getPreviewModePreferences().viewState : getAppState().viewState;
  },

  setViewState(state) {
    updateAppState({ viewState: state });
  },

  getSelectedTabId() {
    return getAppState().selectedTabId;
  },

  setSelectedTabId(tabId) {
    updateAppState({ selectedTabId: tabId });
  },
};
