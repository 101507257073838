import { PlacementType } from '../../constants';
import { getFirstMatchingRefElement } from './../domHelper';

interface SingleElement {
  element: Element;
  type: PlacementType;
  placementIndex?: number;
}

export const findElementBySelector = (selector): SingleElement | undefined => {
  const element = document.querySelector(selector);
  return element ? { type: PlacementType.INLINE, element } : undefined;
};
export const findProductElement = (): SingleElement | undefined =>
  findSingleELement('.shopify-section', ['product'], ['review', 'recommended', 'featured', 'related', 'recent']);

// Covers collection/ collection-template / collection-pages // shopify-section-collection-template
export const findCollectionElement = (): SingleElement | undefined =>
  findSingleELement('.shopify-section', ['collection'], ['featured']);

export const findAllCollectionElement = (): SingleElement | undefined =>
  findSingleELement('.shopify-section', ['list-collections', 'collections-list'], ['featured']);

export const findCartElement = (): SingleElement | undefined => {
  const selectors = [
    '#CartPage',
    '#shopify-section-cart-template',
    '#cart-template',
    '#cartTemplate',
    '[data-section-id="cart"]',
    '[data-section-type="cart"]',
    '#CartContainer',
    '#shopify-section-static-cart',
    '#shopify-section-page-cart',
    '#main',
    '.main-content',
  ];

  const element = getFirstMatchingRefElement(selectors);
  return element ? { type: PlacementType.INLINE, element } : undefined;
};

export const findInlineFooterElement = (): SingleElement | undefined => {
  const selectors = [
    '#shopify-section-footer',
    '#shopify-section-static-footer',
    '#shopify-section-static-footer',
    '#shopify-section-site-footer',
    '#shopify-section-general-footer',
    '#footer',
    'footer',
    '.footer',
    '.copyright',
    '.related-and-upsells',
  ];

  return {
    element: getFirstMatchingRefElement(selectors),
    type: PlacementType.INLINE_ABOVE_FOOTER,
  };
};
const findSingleELement = (sectionSelector, inclusionList, exclusionList): SingleElement | undefined => {
  const elements = findMatchingSections(sectionSelector, inclusionList, exclusionList);

  return {
    element: elements && elements.length === 1 ? elements[0] : undefined,
    type: PlacementType.INLINE,
  };
};

const findMatchingSections = (sectionSelector, inclusionList, exclusionList): Element[] => {
  const sections = Array.from(document.querySelectorAll(sectionSelector));
  if (!sections || sections.length === 0) {
    return [];
  }

  return sections.filter(x => {
    return (
      (x.id && foundPattern(x.id, inclusionList) && !foundPattern(x.id, exclusionList)) ||
      (x.className && foundPattern(x.className, inclusionList) && !foundPattern(x.className, exclusionList))
    );
  });
};

const foundPattern = (str, patterns): boolean => {
  for (let i = 0; i < patterns.length; i++) {
    if (str.indexOf(patterns[i]) > -1) {
      return true;
    }
  }
  return false;
};
