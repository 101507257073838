import { default as cx } from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class MaximizeButton extends Component {
  handleClick = () => {
    this.props.onClick();
  };

  render() {
    const appCustomizations = this.props.appConfiguration.personalization;
    const classNames = cx('adroll-rollie-web-maximize-button', appCustomizations.maximizeButton.location);

    const styles = {
      ...appCustomizations.minimizedLabel.styles,
      ...this.props.appConfiguration.personalization.rollie.styles,
    };
    return (
      <div className={classNames} onClick={this.handleClick} style={styles}>
        <label>{appCustomizations.minimizedLabel.label}</label>
        <button style={appCustomizations.maximizeButton.styles}>＋</button>
      </div>
    );
  }
}

MaximizeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
};

export default MaximizeButton;
