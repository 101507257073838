import React from 'react';
import { track } from '../../..';
import { ATTRIBUTION_TRACKING } from 'utils/constants';

export default function LeftArrow({ previousSlide, currentDirection, appConfiguration }) {
  function onClick() {
    track(ATTRIBUTION_TRACKING.ONSITE_PREV_ARROW, {});
    previousSlide();
  }
  return (
    <svg
      className={`adroll-rollie-left-arrow adroll-rollie-left-arrow-direction-${currentDirection}`}
      onClick={onClick}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 34 59.7"
      style={appConfiguration.personalization.carousel.arrows.styles}
    >
      <g>
        <g>
          <path d="M1.2,32.8C0.4,32,0,30.9,0,29.9c0-1.1,0.4-2.1,1.2-3L26.9,1.2c1.6-1.6,4.3-1.6,5.9,0c1.6,1.6,1.6,4.3,0,5.9 L10.1,29.9l22.7,22.7c1.6,1.6,1.6,4.3,0,5.9c-1.6,1.6-4.3,1.6-5.9,0L1.2,32.8L1.2,32.8z M1.2,32.8" />
        </g>
      </g>
    </svg>
  );
}
