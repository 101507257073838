import { default as cx } from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class HeaderMenuItem extends Component {
  render() {
    const classNames = cx({
      'adroll-rollie-header-menu-item-active': this.props.selected,
    });
    const personalization = this.props.appConfiguration.personalization;
    const style = this.props.selected ? personalization.header.styles : {};
    const title = this.props.title || personalization.header.tabTitles[this.props.tab.customizationName];

    const computedStyle = !this.props.inline
      ? style
      : {
          ...style,
          backgroundColor: '',
        };

    return (
      <button id={this.props.tab.id} className={classNames} onClick={this.handleClick} style={computedStyle}>
        {this.props.inline ? <h3 style={{ fontSize: '1.8em' }}>{title}</h3> : <span>{title}</span>}
      </button>
    );
  }

  handleClick = e => {
    this.props.setSelectedTab(this.props.tab);
    e.preventDefault();
  };
}

HeaderMenuItem.propTypes = {
  tab: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  title: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default HeaderMenuItem;
