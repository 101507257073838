import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { default as cx } from 'classnames';

import TabPicker from 'components/ForestRollie/TabPicker';
import TabButton from 'components/ForestRollie/TabButton';
import Carousel from 'components/ForestRollie/Carousel';

import { ViewState } from 'utils/enum';

class ForestRollie extends Component {
  constructor(props) {
    super(props);
    this.state = { showTabPicker: false };
  }

  toggleTabPicker = () => {
    this.setState({ showTabPicker: !this.state.showTabPicker }, () => {
      this.toggleNoScroll(this.state.showTabPicker);
    });
  };

  toggleNoScroll = flag => {
    let overflow = null;
    if (flag) {
      overflow = 'hidden';
    }
    document.body.style.overflow = overflow;
  };

  toggleViewState = () => {
    if (this.state.showTabPicker) {
      this.toggleTabPicker();
    }
    this.props.toggleViewState();
  };

  setSelectedTab = tab => {
    this.props.setSelectedTab(tab);
    this.toggleTabPicker();
  };

  render() {
    const isMinimized = this.props.viewState === ViewState.MINIMIZED;

    const maximizeButtonClassNames = cx('adroll-rollie-forest-tab-select', {
      'adroll-rollie-forest-hidden': !isMinimized,
      'maximize-button': isMinimized,
    });
    const maximizeButtonStyles = {
      ...this.props.appConfiguration.personalization.minimizedLabel.styles,
      ...this.props.appConfiguration.personalization.maximizeButton.styles,
    };
    const tabPickerClassNames = cx('adroll-rollie-forest-tab-select', {
      'adroll-rollie-forest-hidden': isMinimized,
      selected: this.state.showTabPicker,
    });
    const forestRollieClassNames = cx('adroll-forest-rollie', {
      'adroll-rollie-forest-minimized': isMinimized,
      'adroll-rollie-forest-maximized': !isMinimized,
      'adroll-rollie-preview-mode': this.props.previewModeType,
    });

    const overlayClassNames = cx('adroll-rollie-forest-tab-picker-overlay', {
      hidden: !this.state.showTabPicker,
    });

    return (
      <React.Fragment>
        <div className={overlayClassNames} onClick={this.toggleTabPicker} />
        <div
          className={forestRollieClassNames}
          style={this.props.appConfiguration.personalization.mobile.rollie.styles}
        >
          <TabPicker
            show={this.state.showTabPicker}
            tabs={this.props.tabs}
            customTabsProducts={this.props.customTabsProducts}
            selectedTab={this.props.selectedTab}
            setSelectedTab={this.setSelectedTab}
            appConfiguration={this.props.appConfiguration}
            toggleViewState={this.toggleViewState}
          />
          <div
            className="adroll-rollie-forest-content-container"
            style={this.props.appConfiguration.personalization.mobile.header.styles}
          >
            <button className={maximizeButtonClassNames} onClick={this.toggleViewState} style={maximizeButtonStyles}>
              ＋
            </button>
            <TabButton
              className={tabPickerClassNames}
              onClick={this.toggleTabPicker}
              appConfiguration={this.props.appConfiguration}
              tab={this.props.selectedTab}
              showIcon
            />
            {this.props.tabs.map(tab => (
              <Carousel
                key={tab.id}
                selected={this.props.selectedTab === tab}
                tab={tab}
                appConfiguration={this.props.appConfiguration}
                disabled={this.state.showTabPicker}
                previewModeType={this.props.previewModeType}
              />
            ))}
            {this.props.appConfiguration.personalization.header.customTabs.map(tab => {
              return (
                <Carousel
                  key={tab.id}
                  tab={tab}
                  customTabsProducts={this.props.customTabsProducts}
                  selected={this.props.selectedTab.id === tab.id}
                  disabled={this.state.showTabPicker}
                  appConfiguration={this.props.appConfiguration}
                  previewModeType={this.props.previewModeType}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ForestRollie.propTypes = {
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  toggleViewState: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  viewState: PropTypes.string.isRequired,
  previewModeType: PropTypes.string,
};

export default ForestRollie;
