import { getPreviewModeType } from './previewMode';
import { PREVIEW_MODE_TYPE_WIDGET_ONLY } from './constants';

export function filterTabs(tab, enabledTabsMap) {
  // Filters out tabs when the tab:
  // 1. Is disabled in the customizations;
  // 2. Has no products if the widget is not in the builder.

  return (
    enabledTabsMap[tab.customizationName] &&
    (getPreviewModeType() === PREVIEW_MODE_TYPE_WIDGET_ONLY || tab.products.length > 0)
  );
}
