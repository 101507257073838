import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ATTRIBUTION_TRACKING } from 'utils/constants';
import { track } from '../../../index';
import { navigate } from 'utils/window';

export class ProductCard extends Component {
  handleClick = e => {
    const { idhash, variant_ids, url, source } = this.props.product;

    track(ATTRIBUTION_TRACKING.ONSITE_PRODUCT_SELECT, {
      productId: idhash,
      variantId: variant_ids,
      source,
    });

    // Don't navigate to the product url when in preview mode.
    if (!this.props.previewModeType) {
      navigate(url);
    }
  };

  render() {
    const product = this.props.product;
    return (
      <button
        onClick={this.handleClick}
        disabled={this.props.disabled}
        className="adroll-rollie-forest-product-card"
        productid={product.idhash}
        source={product.source}
      >
        <img src={product['i/image']} alt={product.title} />
      </button>
    );
  }
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  previewModeType: PropTypes.string,
};

export default ProductCard;
