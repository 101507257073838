import { default as cx } from 'classnames';
import PoweredByAdroll from 'components/common/WaterMark';
import Content from 'components/WebRollie/Content';
import Header from 'components/WebRollie/Header';
import MaximizeButton from 'components/WebRollie/MaximizeButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ViewState } from 'utils/enum';
import { showWaterMark } from 'utils/showWaterMark';
class WebRollie extends Component {
  extraClasses = [];

  render() {
    const personalization = this.props.appConfiguration.personalization;

    const rollieStyles =
      this.props.viewState === ViewState.MINIMIZED
        ? {
            ...personalization.rollie.styles,
            // bottom: -this.props.dimensions.height, // This is incase there are fixed elements below
          }
        : personalization.rollie.styles;
    const { inline } = this.props;
    const classNames = cx(
      'adroll-web-rollie',
      `adroll-web-rollie-${this.props.viewState}`,
      `adroll-web-rollie-${personalization.alignment.open}`,
      this.extraClasses,
      {
        'adroll-fixed-footer': !inline,
        'adroll-rollie-preview-mode': this.props.previewModeType,
      }
    );

    return (
      <div className={classNames} style={rollieStyles}>
        <Header
          tabs={this.props.tabs}
          customTabsProducts={this.props.customTabsProducts}
          selectedTab={this.props.selectedTab}
          setSelectedTab={this.props.setSelectedTab}
          toggleViewState={this.props.toggleViewState}
          appConfiguration={this.props.appConfiguration}
          cartData={this.props.cartData}
          config={this.props.config}
          previewModeType={this.props.previewModeType}
          inline={inline}
        />

        <Content
          tabs={this.props.tabs}
          customTabsProducts={this.props.customTabsProducts}
          selectedTab={this.props.selectedTab}
          setSelectedTab={this.props.setSelectedTab}
          appConfiguration={this.props.appConfiguration}
          dimensions={this.props.dimensions}
          cartData={this.props.cartData}
          config={this.props.config}
          previewModeType={this.props.previewModeType}
          inline={inline}
        />
        {inline && showWaterMark(this.props.appConfiguration) && <PoweredByAdroll inline={inline} />}
        {!inline && (
          <MaximizeButton onClick={this.props.toggleViewState} appConfiguration={this.props.appConfiguration} />
        )}
      </div>
    );
  }
}

WebRollie.propTypes = {
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  viewState: PropTypes.string.isRequired,
  toggleViewState: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  cartData: PropTypes.object,
  config: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default WebRollie;
