import { default as deepmerge } from 'deepmerge';
import promise from 'es6-promise';
import { defaultArray, getDeepObject } from '../utils';
import { getQueryStringObject } from '../utils/window';
import { getS3Customizations } from './api/s3';
import {
  APP_STATE_KEY,
  CONFIGURATION_FILE_NAME,
  DEFAULT_DESKTOP_PLACEMENT,
  DEFAULT_MOBILE_PLACEMENT,
  NO_BUILDER_CUSTOMIZATIONS_FILE_NAME,
} from './constants';
import {
  getPreviewModeCustomizations,
  getPreviewModeType,
} from './previewMode';
import { readFromSession, writeToSession } from './storage';

const LARGE_VERSION_NUMBER = 100000;
export const noBuilderCustomizationsDefaultValues = {
  personalization: {},
  show: {
    browsers: [
      { name: 'chrome', minVersion: 35, maxVersion: LARGE_VERSION_NUMBER },
      { name: 'edge', minVersion: 17, maxVersion: LARGE_VERSION_NUMBER },
      { name: 'firefox', minVersion: 30, maxVersion: LARGE_VERSION_NUMBER },
      { name: 'safari', minVersion: 9, maxVersion: 9 },
      { name: 'safari', minVersion: 11, maxVersion: LARGE_VERSION_NUMBER },
      {
        name: 'mobile safari',
        minVersion: 10,
        maxVersion: LARGE_VERSION_NUMBER,
      },
    ],
    screenSizeInPixels: { minWidth: 300, maxWidth: 2100 },
    devices: { desktop: true, mobile: true },
  },
  features: {
    addToCart: true,
    showProductPrice: true,
    cardsToShow: null,
    showCartLink: true,
  },
  productGroup: {
    default: null,
    urlRules: [], // Can be an array of {'regex_rule': 'regex_rule', 'product_group': 'group_to_use'}
  },
};

const defaultCustValues = {
  defaultPlacement: { theme: 'fixedfooter' },
  placements: {
    desktop: DEFAULT_DESKTOP_PLACEMENT,
    mobile: DEFAULT_MOBILE_PLACEMENT,
  },
  adaptToWebsiteStyles: true,
  alignment: {
    open: 'full-width',
  },
  rollie: {
    styles: {
      zIndex: 99999999,
      fontSize: 16, // pixels
    },
  },
  header: {
    styles: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
      fontSize: 14, // pixels
    },
    enabledTabs: {
      top_products: true,
      recommendations: true,
      identity: true,
    },
    tabTitles: {
      top_products: 'Popular',
      recommendations: 'For You',
      identity: 'Recents',
    },
    customTabs: [], // Refer to onsite/app/src/utils/personalization.js for example of this value.
  },
  carousel: {
    styles: {
      backgroundColor: '#FFFFFF',
    },
    arrows: {
      styles: {
        fill: '#231f20',
      },
    },
  },
  product: {
    styles: {},
    actionButton: {
      styles: {
        backgroundColor: '#F06C54',
        color: '#FFFFFF',
      },
    },
    title: {
      styles: {
        color: '#000000',
      },
    },
    price: {
      styles: {
        color: '#000000',
      },
    },
    align: {
      fixedfooter: 'left',
    },
  },
  minimizedLabel: {
    styles: {
      backgroundColor: '#FFFFFF',
    },
    delayExpanding: 0,
    expandOnPages: ['home', 'product'],
    label: 'Your Items',
  },
  maximizeButton: {
    location: 'bottom-left',
  },
  minimizeButton: {
    styles: {
      borderColor: '#000000',
      color: '#000000',
    },
  },
  shoppingCart: {
    iconName: 'cart',
  },
  desktopShoppingCart: {
    text: 'CART',
  },
  template: null,
  mobileTemplate: null,
};
export const customizationDefaultValues = {
  ...defaultCustValues,
  mobile: {
    ...defaultCustValues,
  },
};

const productStyles: any = customizationDefaultValues.product.styles;
// Need to set this as the em could be reset inside `Nuka-carousel`
productStyles.fontSize = customizationDefaultValues.rollie.styles.fontSize;

const configurationDefaultValues = {
  ecommerce_platform: null,
  personalization: customizationDefaultValues,
  noBuilderCustomizations: noBuilderCustomizationsDefaultValues,
};

const isCustomizationTestModeInUrl = () => {
  const adrollOnsiteWidgetMode = getQueryStringObject()
    .adroll_onsite_widget_mode;
  return adrollOnsiteWidgetMode === 'test';
};

export const isCustomizationTestModeActive = () => {
  const sessionKey = `${APP_STATE_KEY}_adroll_onsite_widget_mode`;
  const obj = readFromSession(sessionKey);

  if (obj && obj.test === true) {
    return true;
  }

  if (isCustomizationTestModeInUrl()) {
    writeToSession(sessionKey, { test: true });
    return true;
  }

  return false;
};

export const disableCustomizationTestMode = () => {
  writeToSession(`${APP_STATE_KEY}_adroll_onsite_widget_mode`, { test: false });
  // Reload page without query string parameters.
  window.location.href = window.location.pathname;
};

export async function getAppConfiguration(advertisableEid) {
  /*
    Gets the advertisable customizations and the noBuilderCustomizations and add
    a `noBuilderCustomizations` key to the main appCustomization object.
  */
  const noBuilderPromise = getNoBuilderCustomizations(advertisableEid);
  const configurationPromise = getAppConfigurationHelper(
    advertisableEid,
    configurationDefaultValues,
    CONFIGURATION_FILE_NAME
  );

  return promise.Promise.all([noBuilderPromise, configurationPromise]).then(
    ([noBuilderCustomizations, appConfiguration]) => {
      appConfiguration['noBuilderCustomizations'] = noBuilderCustomizations;

      appConfiguration.personalization = deepmerge(
        appConfiguration.personalization,
        noBuilderCustomizations.personalization,
        {
          arrayMerge: defaultArray,
        }
      );

      if (isCustomizationTestModeActive()) {
        // Check if we have some special url customization query string parameters
        // and merge it with our S3 customizations.
        const customUrlCustomizations = getUrlCustomizationsObject();
        if (customUrlCustomizations) {
          appConfiguration.personalization = deepmerge(
            appConfiguration.personalization,
            customUrlCustomizations,
            {
              arrayMerge: defaultArray,
            }
          );
        }
      }
      return appConfiguration;
    }
  );
};

export const getNoBuilderCustomizations = advertisableEid => {
  return getAppConfigurationHelper(
    advertisableEid,
    noBuilderCustomizationsDefaultValues,
    NO_BUILDER_CUSTOMIZATIONS_FILE_NAME
  ).catch(err => {
    /* Return default values if no overrides are found */
    return noBuilderCustomizationsDefaultValues;
  });
};

const noBuilderHelper = (advertisableEid, customizationsObj, shortFileName) => {
  return getS3Customizations(
    advertisableEid,
    isCustomizationTestModeActive(),
    shortFileName
  )
    .then(overrides => {
      overrides.personalization = overrides.personalization || {};

      return deepmerge(customizationsObj, overrides, {
        arrayMerge: defaultArray,
      });
    })
    .catch(err => {
      return customizationsObj;
    });
};

const builderHelper = (advertisableEid, customizationsObj, shortFileName) => {
  return getS3Customizations(
    advertisableEid,
    isCustomizationTestModeActive(),
    shortFileName
  )
    .then(overrides => {
      overrides.personalization = overrides.personalization || {};
      /* Since we currently dont have seperate configuration for mobile, we have to clone the one for desktop */
      if (!overrides.personalization.mobile) {
        overrides.personalization.mobile = {
          ...overrides.personalization,
        };
      }
      return deepmerge(customizationsObj, overrides, {
        arrayMerge: defaultArray,
      });
    })
    .catch(err => {
      return customizationsObj;
    });
};

export const getAppConfigurationHelper = (
  advertisableEid,
  customizationsObj,
  shortFileName
) => {
  // We always fetch from S3 if:
  // * we are not in preview mode
  // * we are getting no builder customizations
  if (
    !getPreviewModeType() ||
    shortFileName === NO_BUILDER_CUSTOMIZATIONS_FILE_NAME
  ) {
    const helper =
      shortFileName === NO_BUILDER_CUSTOMIZATIONS_FILE_NAME
        ? noBuilderHelper
        : builderHelper;
    return helper(advertisableEid, customizationsObj, shortFileName);
  } else {
    return getPreviewModeCustomizations().then((overrides: any) => {
      customizationsObj.ecommerce_platform = overrides.ecommerce_platform;
      const personalization = deepmerge(
        customizationsObj.personalization,
        overrides.personalization,
        { arrayMerge: defaultArray }
      );
      return {
        ...customizationsObj,
        ...{ personalization: personalization },
      };
    });
  }
};

export const doWork = async () => {
  return getS3Customizations('abc', false, '3');
};

export const getUrlCustomizationsObject = () => {
  // Get all url parameter in the format of adr[level1__level2__level3__]=value
  // and convert it into a nice object like {level1: {level2: {level3: value}}}.
  const queryStringObject = getQueryStringObject();
  const customUrlCustomizations = {};
  Object.entries(queryStringObject).forEach(([key, value]) => {
    const property = /adr\[(.*)\]/.exec(key);
    if (property) {
      const propParts = property[1].split('__');
      const obj = getDeepObject(
        customUrlCustomizations,
        propParts.slice(0, propParts.length - 1),
        true
      );
      obj[propParts[propParts.length - 1]] = value;
    }
  });
  return customUrlCustomizations;
};
