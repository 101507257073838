import { DEFAULT_DESKTOP_PLACEMENT, DEFAULT_MOBILE_PLACEMENT } from './constants';
import { isMobile } from './isDnd';

export const isInline = (appConfiguration, config, dimensions) => {
  const placements = appConfiguration.personalization.placements;
  const mobile = isMobile(config, dimensions);
  return (
    config.inline ||
    (mobile && placements.mobile !== DEFAULT_MOBILE_PLACEMENT) ||
    (!mobile && placements.desktop !== DEFAULT_DESKTOP_PLACEMENT)
  );
};
