import React from 'react';
import { track } from '../../..';
import { ATTRIBUTION_TRACKING } from 'utils/constants';

export default function RightArrow({ nextSlide, currentDirection, appConfiguration }) {
  function onClick() {
    track(ATTRIBUTION_TRACKING.ONSITE_NEXT_ARROW, {});
    nextSlide();
  }
  return (
    <svg
      className={`adroll-rollie-right-arrow adroll-rollie-right-arrow-direction-${currentDirection}`}
      onClick={onClick}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 34 59.7"
      style={appConfiguration.personalization.carousel.arrows.styles}
    >
      <g>
        <g>
          <path d="M32.8,32.8L7.1,58.5c-1.6,1.6-4.3,1.6-5.9,0c-1.6-1.6-1.6-4.3,0-5.9l22.7-22.7L1.2,7.1 c-1.6-1.6-1.6-4.3,0-5.9c1.6-1.6,4.3-1.6,5.9,0l25.7,25.7c0.8,0.8,1.2,1.9,1.2,3C34,30.9,33.6,32,32.8,32.8L32.8,32.8z M32.8,32.8" />
        </g>
      </g>
    </svg>
  );
}
