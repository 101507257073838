import Carousel from 'components/WebRollie/Carousel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getWebContentHeight } from 'utils/webContentScaling';
class Content extends Component {
  componentDidMount() {
    // Simulate a click on the currently selected tab to force
    // the NukaCarousel to render correctly.
    setTimeout(() => {
      this.props.setSelectedTab(this.props.selectedTab, true);
    }, 3000);
  }

  render() {
    const contentStyles = this.getContentStyles();

    return (
      <div className="adroll-rollie-web-content" style={contentStyles}>
        {this.props.tabs.map(tab => {
          return (
            <Carousel
              key={tab.id}
              products={tab.products}
              dimensions={this.props.dimensions}
              selected={this.props.selectedTab.id === tab.id}
              appConfiguration={this.props.appConfiguration}
              cartData={this.props.cartData}
              config={this.props.config}
              previewModeType={this.props.previewModeType}
              inline={this.props.inline}
            />
          );
        })}
        {this.props.appConfiguration.personalization.header.customTabs
          .filter(tab => {
            return this.props.customTabsProducts[tab.id] && tab.enabled;
          })
          .map(tab => {
            return (
              <Carousel
                key={tab.id}
                products={this.props.customTabsProducts[tab.id]}
                dimensions={this.props.dimensions}
                selected={this.props.selectedTab.id === tab.id}
                appConfiguration={this.props.appConfiguration}
                cartData={this.props.cartData}
                config={this.props.config}
                previewModeType={this.props.previewModeType}
                inline={this.props.inline}
              />
            );
          })}
      </div>
    );
  }

  getContentStyles = () => {
    const contentStyles = {
      ...this.props.appConfiguration.personalization.carousel.styles,
      height: getWebContentHeight(this.props.appConfiguration, this.props.inline),
      backgroundColor: 'none',
    };
    return contentStyles;
  };
}

Content.propTypes = {
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  cartData: PropTypes.object,
  config: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default Content;
