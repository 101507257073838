import { default as cx } from 'classnames';
import Content from 'components/MobileRollie/Content';
import Header from 'components/MobileRollie/Header';
import MaximizeButton from 'components/MobileRollie/MaximizeButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class MobileRollie extends Component {
  render() {
    // 'selectedProduct' is for the mobile header menu. In there, we don't need
    // to know the selected product index but rather the product itself, so we
    // determine that here and pass it along.
    let selectedProduct = null;
    const { selectedProductIndex, selectedTab } = this.props;
    if (selectedProductIndex !== null) {
      // strict check against null because zero is a valid index
      selectedProduct = selectedTab.products[selectedProductIndex];
    }

    const { inline } = this.props;

    const classNames = cx('adroll-mobile-rollie', `adroll-mobile-rollie-${this.props.viewState}`, {
      'adroll-rollie-preview-mode': this.props.previewModeType,
      'adroll-fixed-footer': !inline,
    });

    return (
      <div className={classNames} style={this.props.appConfiguration.personalization.mobile.rollie.styles}>
        <Header
          tabs={this.props.tabs}
          customTabsProducts={this.props.customTabsProducts}
          selectedTab={this.props.selectedTab}
          setSelectedTab={this.props.setSelectedTab}
          selectedProduct={selectedProduct}
          toggleViewState={this.props.toggleViewState}
          appConfiguration={this.props.appConfiguration}
          cartData={this.props.cartData}
          config={this.props.config}
          previewModeType={this.props.previewModeType}
          inline={inline}
        />

        <Content
          tabs={this.props.tabs}
          customTabsProducts={this.props.customTabsProducts}
          selectedTab={this.props.selectedTab}
          selectedProductIndex={this.props.selectedProductIndex}
          setSelectedProductIndex={this.props.setSelectedProductIndex}
          appConfiguration={this.props.appConfiguration}
          dimensions={this.props.dimensions}
          previewModeType={this.props.previewModeType}
        />

        {!inline && (
          <MaximizeButton
            onClick={this.props.toggleViewState}
            appConfiguration={this.props.appConfiguration}
            useMobileCssClass={true}
          />
        )}
      </div>
    );
  }
}

MobileRollie.propTypes = {
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  selectedProductIndex: PropTypes.number,
  setSelectedProductIndex: PropTypes.func.isRequired,
  viewState: PropTypes.string.isRequired,
  toggleViewState: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  cartData: PropTypes.object,
  config: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default MobileRollie;
