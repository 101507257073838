import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as DoubleUpCaret } from 'components/ForestRollie/images/doubleUpCaret.svg';

export class TabButton extends Component {
  render() {
    const personalization = this.props.appConfiguration.personalization.mobile;
    const title = this.props.tab.title || personalization.header.tabTitles[this.props.tab.customizationName];

    return (
      <button className={this.props.className} onClick={this.props.onClick} style={personalization.header.styles}>
        {this.props.showIcon ? <DoubleUpCaret /> : null} <span>{title}</span>
      </button>
    );
  }
}

TabButton.propTypes = {
  tab: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
};

export default TabButton;
