import { default as deepmerge } from 'deepmerge';
import React from 'react';
import { AppProps, AppState } from './common/interfaces';
import RollieContainer from './containers/RollieContainer.jsx';
import './styles/App.scss';
import { defaultArray } from './utils';
import { APP_STATE_KEY, MEDIA_QUERY_THEMES, SAFE_ORIGINS_MAP } from './utils/constants';
import { isCustomizationTestModeActive } from './utils/customization';
import { isInline } from './utils/isInline';
import { readFromSession } from './utils/storage';
import { addEventListener, removeEventListener } from './utils/window';

export class AppWrapped extends React.Component<AppProps, AppState> {
  constructor(props) {
    super(props);
    this.state = { config: this.props.config };
  }

  render() {
    return (
      <RollieContainer theme={MEDIA_QUERY_THEMES.DESKTOP} {...this.props} />
    );
  }
}

class App extends React.Component<AppProps, AppState> {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      forceDisplay: false,
      appConfiguration: props.appConfiguration,
      receivedPreviewModeBuilderCustomizations: false,
      config: props.config,
    };
  }

  componentDidMount() {
    const forceLocalObj = readFromSession(`${APP_STATE_KEY}_FORCE_LOCAL`);
    const forceDisplay = forceLocalObj && forceLocalObj.display;
    this.setState({
      forceDisplay: forceDisplay,
      appConfiguration: this.props.appConfiguration,
    });
    if (this.props.previewModeType) {
      addEventListener('message', this.receivePreviewModeCustomizations);
    }
  }

  componentWillUnmount() {
    removeEventListener('message', this.receivePreviewModeCustomizations);
  }

  getDisplay = noBuilderCustomizations => {
    const {
      dimensions: { width },
    } = this.props;
    const {
      config: { browserName, version, deviceType },
    } = this.state;
    const versionVal = parseInt(version, 10);
    const {
      show: { browsers, screenSizeInPixels, devices },
    } = noBuilderCustomizations;
    const found =
      browsers.filter(
        b =>
          b.name === browserName &&
          versionVal >= b.minVersion &&
          versionVal <= b.maxVersion
      ).length > 0;
    return (
      found &&
      width >= screenSizeInPixels.minWidth &&
      width <= screenSizeInPixels.maxWidth &&
      devices[deviceType]
    );
  };

  render() {
    /*
          This call is moved here rather cause `componentDidMount` is only triggered once
          and before the parent Dimensions component has had a chance to evaluate the window size
      */
    const display = this.getDisplay(
      this.props.appConfiguration.noBuilderCustomizations
    );
    if (
      (!display && !this.state.forceDisplay && !this.props.previewModeType) ||
      !this.state.appConfiguration
    ) {
      return null;
    }
    const inline = isInline(
      this.state.appConfiguration,
      this.props.config,
      this.props.dimensions
    );
    return (
      <AppWrapped
        {...this.props}
        config={this.state.config}
        display={
          this.state.forceDisplay ||
          this.props.previewModeType !== undefined ||
          isCustomizationTestModeActive()
        }
        receivedPreviewModeBuilderCustomizations={
          this.state.receivedPreviewModeBuilderCustomizations
        }
        appConfiguration={this.state.appConfiguration}
        inline={inline}
      />
    );
  }

  receivePreviewModeCustomizations = e => {
    if (!e.data.adrollRollieCustomizationPreview) {
      // ignore events unrelated to preview mode
      return;
    }
    /*
     * The mobile components expect a `.mobile` key under the main
     * personalization object for their customizations.
     * However, the builder does not fill that key in on update, resulting in
     * the mobile previews breaking.
     * This `mobile` object is just a straight copy of the `personalization`
     * object, so we can just assign the object to a `mobile` key.
     * Check `src/utils/customization.js:builderHelper` for where the builder
     * does this on the first load.
     */
    const newConfig = {
      ...this.state.config,
      ...e.data.adrollRolliePreferencesPreview,
    };
    const appConfiguration = e.data.adrollRollieCustomizationPreview;
    const noBuilderCustomizations = this.state.appConfiguration
      .noBuilderCustomizations;
    appConfiguration.personalization = deepmerge(
      appConfiguration.personalization,
      noBuilderCustomizations.personalization,
      {
        arrayMerge: defaultArray,
      }
    );
    appConfiguration.personalization.mobile = appConfiguration.personalization;
    this.setState({
      receivedPreviewModeBuilderCustomizations: true,
      appConfiguration: {
        ...appConfiguration,
        ...{
          noBuilderCustomizations: this.state.appConfiguration
            .noBuilderCustomizations,
        },
      },
      config: newConfig,
    });
  };
}

export default App;
