import { default as cx } from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class HeaderMenuItem extends Component {
  render() {
    const classNames = cx({
      'adroll-rollie-header-menu-item-active': this.props.selected,
    });
    const personalization = this.props.appConfiguration.personalization.mobile;
    const style = this.props.selected ? personalization.header.styles : {};
    const title = this.props.title || personalization.header.tabTitles[this.props.tab.customizationName];
    return (
      <button className={classNames} onClick={this.handleClick} style={style}>
        {title}
      </button>
    );
  }

  handleClick = e => {
    this.props.setSelectedTab(this.props.tab);
    e.preventDefault();
  };
}

HeaderMenuItem.propTypes = {
  tab: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default HeaderMenuItem;
