import { AdrollWindow } from "../common/adroll-window";

/**
Custom pixel uev logline generator.
The helper functions here were copied and adapted a little from the hazmat project, which is the pixel code project.
 */

 declare var window: AdrollWindow;

function parseUri(str: string) {
  const m = /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/.exec(
      str
    ),
    uri = { queryKey: {} },
    key = [
      'source',
      'protocol',
      'authority',
      'userInfo',
      'user',
      'password',
      'host',
      'port',
      'relative',
      'path',
      'directory',
      'file',
      'query',
      'anchor',
    ],
    parser = /(?:^|&)([^&=]*)=?([^&]*)/g;
  let i = 14;

  while (i--) {
    uri[key[i]] = m[i] || '';
  }

  uri[key[12]].replace(parser, function($0, $1, $2) {
    if ($1) {
      uri['queryKey'][$1] = $2;
    }
  });

  return uri;
}

function addQueryParameterToUrl(url, name, value) {
  const parts = parseUri(url);
  if (parts.queryKey[name]) {
    return url;
  }
  const qindex = url.indexOf('?');
  let prefix = '',
    suffix = '';
  if (qindex !== -1) {
    prefix = url.slice(0, qindex + 1);
    suffix = '&' + url.slice(qindex + 1);
  } else {
    var hindex = url.indexOf('#', qindex === -1 ? 0 : qindex);
    if (hindex === -1) {
      prefix = url + '?';
    } else {
      prefix = url.slice(0, hindex) + '?';
      suffix = url.slice(hindex);
    }
  }
  return prefix + name + '=' + encodeURIComponent(value) + suffix;
}

function getCookie(cookieName: string) {
  const cookie = window.document.cookie;
  if (cookie === null) {
    return null;
  }
  let cookieNameStart, valueStart, valueEnd, cookieValue, returnValue;
  cookieNameStart = cookie.indexOf(cookieName + '=');
  if (cookieNameStart < 0) {
    returnValue = null;
  } else {
    valueStart = cookie.indexOf(cookieName + '=') + cookieName.length + 1;
    valueEnd = cookie.indexOf(';', valueStart);
    if (valueEnd === -1) {
      valueEnd = cookie.length;
    }
    cookieValue = cookie.substring(valueStart, valueEnd);
    returnValue = cookieValue === '' ? null : unescape(cookieValue);
  }
  return returnValue;
}

function get_first_party_cookie() {
  const samesiteFlag = '-s2-',
    fpc = getCookie('__adroll_fpc');
  if (fpc) {
    if (fpc.indexOf(samesiteFlag) === -1) {
      return null;
    }
    return fpc.replace(samesiteFlag, '-').replace(/-$/, '');
  }
  return null;
}

export function _srv(url) {
  let newUrl = 'https://d.adroll.com' + url;
  if (window.adroll_tpc) {
    newUrl = addQueryParameterToUrl(newUrl, 'adroll_tpc', window.adroll_tpc);
  }
  const fpc = get_first_party_cookie();
  if (fpc) {
    newUrl = addQueryParameterToUrl(newUrl, 'adroll_fpc', fpc);
  }

  return newUrl;
}

function _head() {
  return (
    (window.document.getElementsByTagName('head') || [null])[0] ||
    (window.document.getElementsByTagName('body') || [null])[0] ||
    window.document.getElementsByTagName('script')[0].parentNode
  );
}

function imgRequest(url) {
  var img = new Image();
  img.src = url;
  img.setAttribute('width', '1');
  img.setAttribute('height', '1');
  img.setAttribute('border', '0');
  _head().appendChild(img);
}

export function trackUEV(eventName, eventAttrs) {
  var src = _srv(
    '/uev/' +
      window.adroll_adv_id +
      '/' +
      window.adroll_pix_id +
      '/?event_name=' +
      encodeURIComponent(eventName) +
      '&adroll_version=1.0'
  );

  if (eventAttrs) {
    src +=
      '&event_attributes=' +
      encodeURIComponent(JSON.stringify(eventAttrs));
  }

  imgRequest(src);
}
