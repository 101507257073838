import { getHref, getPathName } from 'utils/window';

const pagePlacementMapping = {
  shopify: {
    home: () => getPathName() === '/',
    product: () => getHref().indexOf('/products/') !== -1 || getHref().indexOf('/product/') !== -1,
    cart: () => getPathName().indexOf('/cart') !== -1,
    collection: () => getHref().indexOf('/product') === -1 && getPathName().indexOf('/collection') !== -1,
    search: () => getPathName().indexOf('/search') !== -1,
    blog: () => getPathName().indexOf('/blog') !== -1,
    404: () => getPathName().indexOf('/404') !== -1,
    conversion: () =>
      typeof window !== 'undefined' &&
      typeof window.Shopify !== 'undefined' &&
      window.Shopify.Checkout !== undefined &&
      window.Shopify.Checkout.page !== undefined &&
      window.Shopify.Checkout.page === 'thank_you',
    bold: () =>
      typeof window.BOLD !== 'undefined' && window.BOLD.order !== undefined && window.BOLD.order.id !== undefined,
  },
  bigcommerce: {
    home: () => getPathName() === '/',
    product: () => {
      const pageType = getInputValueOrNull(document.querySelector('meta[property="og:type"]'));

      return pageType === 'product';
    },
    search: () => getPathName().indexOf('/search.php') !== -1,
    orderconfirmation: () =>
      getPathName().indexOf('/order-confirmation') !== -1 || getPathName().indexOf('/finishorder.php') !== -1,
    checkout: () => getPathName() === '/checkout' || getPathName() === '/checkout.php',
    cart: () => getPathName().indexOf('/cart.php') !== -1,
    collection: () => getHref().indexOf('/product') === -1 && getPathName().indexOf('/collection') !== -1,
  },
  woocommerce: {
    home: () => getCurrentPage() && getCurrentPage() === 'home_page',
    search: () => getCurrentPage() && getCurrentPage() === 'search_page',
    product: () => getCurrentPage() && getCurrentPage() === 'product_page',
    cart: () => getCurrentPage() && getCurrentPage() === 'cart_page',
    checkout: () => getCurrentPage() && getCurrentPage() === 'checkout_page',
    conversion: () => getCurrentPage() && getCurrentPage() === 'conversion_page',
    collection: () => getHref().indexOf('/product') === -1 && getPathName().indexOf('/collection') !== -1,
  },
  prestashop: {},
  squarespace: {
    home: () => getPathName() === '/',
    product: () => {
      const pageType = getInputValueOrNull(document.querySelector('meta[property="og:type"]'));

      return pageType === 'product';
    },
    search: () => getPathName().indexOf('/search') !== -1,
    checkout: () => getPathName().indexOf('/checkout') !== -1,
    orderconfirmation: () => getPathName().indexOf('/order-confirmed') !== -1,
    cart: () => getPathName() === '/cart',
    collection: () => getHref().indexOf('/product') === -1 && getPathName().indexOf('/collection') !== -1,
  },
  magento: {},
  magento1: {},
  magento2: {},
};

pagePlacementMapping.prestashop = {
  ...pagePlacementMapping.woocommerce,
};
pagePlacementMapping.magento = {
  ...pagePlacementMapping.woocommerce,
};
pagePlacementMapping.magento1 = {
  ...pagePlacementMapping.woocommerce,
};
pagePlacementMapping.magento2 = {
  ...pagePlacementMapping.woocommerce,
};

const getInputValueOrNull = input => {
  return input !== null ? input.value : null;
};

const getCurrentPage = () => {
  if (typeof window !== 'undefined' && typeof window.adroll_current_page !== 'undefined') {
    return window.adroll_current_page;
  }
  return null;
};

export const getPagePlacementMapping = () => pagePlacementMapping;
