import { PRODUCT_COLLECTIONS } from './constants';

export const mapProductStreamsToCollection = data => {
  const products = [];
  if (
    !data ||
    !data.identityProducts ||
    !data.recommendedProducts ||
    !data.topProducts
  ) {
    return products;
  }

  const addToHash = (productHash, prodCollection, productKey) => {
    prodCollection
      .filter(x => x.idhash !== undefined)
      .forEach(x => {
        const recVal = productHash[x.idhash] ? productHash[x.idhash] : x;
        const newRec = {
          ...recVal,
          [productKey]: true,
        };
        productHash[x.idhash] = newRec;
      });
  };
  const productHash = {};
  addToHash(
    productHash,
    // Reversing the list so most recently viewed product appears on the right.
    data.identityProducts.reverse(),
    PRODUCT_COLLECTIONS.identity.productKey
  );
  addToHash(
    productHash,
    data.recommendedProducts,
    PRODUCT_COLLECTIONS.recommendations.productKey
  );
  addToHash(
    productHash,
    data.topProducts,
    PRODUCT_COLLECTIONS.top_products.productKey
  );

  // @ts-ignore
  return Object.values(productHash);
};

export const mapDefaultVariants = products => {
  return products.map(x => {
    const newRec = {
      ...x,
      defaultVariant: true,
    };
    return newRec;
  });
};

export const getViewStates = products => {
  let topProducts = false,
    identity = false,
    recommendations = false;

  products.forEach(p => {
    topProducts =
      topProducts ||
      p[`${PRODUCT_COLLECTIONS.top_products.productKey}`] ||
      false;
    identity =
      identity || p[`${PRODUCT_COLLECTIONS.identity.productKey}`] || false;
    recommendations =
      recommendations ||
      p[`${PRODUCT_COLLECTIONS.recommendations.productKey}`] ||
      false;
  });

  const allowedViews = [];

  if (topProducts) {
    allowedViews.push(PRODUCT_COLLECTIONS.top_products);
  }

  if (recommendations) {
    allowedViews.push(PRODUCT_COLLECTIONS.recommendations);
  }

  if (identity) {
    allowedViews.push(PRODUCT_COLLECTIONS.identity);
  }

  return allowedViews;
};

export const mapVisibleProductsToTrackBody = productVariants => {
  return productVariants.map(product => {
    return {
      source: product.source,
      productId: product.id,
      actionType:
        product.variant_ids && product.variant_ids.split(',').length === 1
          ? 'Add'
          : 'View',
    };
  });
};

export const getAllowedView = (selectedView, allowedViews) => {
  let found =
    allowedViews.filter(x => x.productKey === selectedView.productKey).length >
    0;
  return found ? selectedView : allowedViews[0];
};

/**
 * We are only interested in Vanilla products.  That is products which have only 1 variant
 * @param  products
 */
export const getProductVariantsFromProducts = products => {
  const productVariants = [];
  products.forEach(product => {
    if (product.variants && product.variants.length === 1) {
      product.variants.forEach(v => {
        productVariants.push({
          id: v.id,
          sku: v.sku,
        });
      });
    }
  });
  return productVariants;
};
