import promise from 'es6-promise';

export const makeRequest = (
  method: string,
  url: string,
  opts?: any,
  withCredentials: boolean = true
): promise.Promise<any> => {
  return new promise.Promise(function(resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.withCredentials = withCredentials;
    xhr.onload = function() {
      if ((this.status >= 200 && this.status < 300) || this.status === 404 || this.status === 403) {
        if (xhr.response) {
          try {
            resolve(xhr.response);
          } catch (e) {
            console.log(`Error:${e}`);
          }
        }
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function() {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    let data = {};
    if (opts) {
      if (opts.headers) {
        Object.keys(opts.headers).forEach(function(key) {
          xhr.setRequestHeader(key, opts.headers[key]);
        });
      }
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      data = JSON.stringify(opts.data || {});
    }

    // @ts-ignore
    xhr.send(data);
  });
};
