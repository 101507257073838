import promise from 'es6-promise';
import { makeRequest } from './api';
import { safeJsonParse } from '../index';
import { ATTRIBUTION_TRACKING } from '../constants';

export const addToCart = (quantity, cartId, productId, variantId) => {
  const url = cartId ? `/api/storefront/carts/${cartId}/items` : `/api/storefront/cart`;
  return makeRequest('POST', url, {
    data: {
      lineItems: [
        {
          quantity: parseInt(quantity, 10),
          productId,
          variantId,
        },
      ],
    },
  }).then(resp => (resp ? JSON.parse(resp) : undefined));
};

export const getCartFromApi = () => {
  return makeRequest('GET', '/api/storefront/cart').then(resp => {
    if (typeof resp === 'undefined') {
      return promise.Promise.reject(new Error('Error getting bigcommerce cart contents.'));
    }
    return resp ? safeJsonParse(resp) : undefined;
  });
};

export const getCart = () => {
  return getCartFromApi().then(apiResponse => {
    return getCartData(apiResponse);
  });
};

export const getCartData = apiResponse => {
  const cartData = [];
  let cartId = null;
  if (apiResponse && apiResponse.length > 0 && apiResponse[0].lineItems) {
    cartId = apiResponse[0].id;
    Object.entries(apiResponse[0].lineItems).forEach(item => {
      item[1].forEach(item => {
        if (item.productId) {
          cartData.push(item.productId);
        }
      });
    });
  }
  return {
    cartId,
    items: cartData,
    itemsCount: getCartCount(apiResponse),
    cartUrl: '/cart.php',
  };
};

export const getCartCount = apiResponse => {
  let count = 0;
  if (apiResponse && apiResponse.length > 0 && apiResponse[0].lineItems) {
    Object.entries(apiResponse[0].lineItems).forEach(item => {
      item[1].forEach(item => {
        count = count + item.quantity;
      });
    });
  }
  return count;
};

export const addToCartHandler = (e, track, cartData, product) => {
  if (e) {
    e.stopPropagation(); // Needed to stop bubbling up (and triggering a product view track event)
    e.preventDefault(); // This event might be within a <a> tag
  }
  const cartId = cartData && cartData.data ? cartData.data.cartId : null;
  const variantId = product.variant_ids;
  const productId = product.id;

  track(ATTRIBUTION_TRACKING.ONSITE_ADD_TO_CART, {
    productId,
    variantId,
    source: product.source,
    quantity: 1,
  });
  return addToCart(1, cartId, productId, variantId);
};
