import { getPreviewModeType } from './previewMode';
import { getTrackFn } from './window';
import { isElementInViewPort } from './index';
import { MemoryStorage } from './storage';
import { MEMORY_STORAGE } from './constants';

export const buildTrackFn = (browserName, deviceType, template, isInline) => {
  const _prepareTrackContext = (context = {}) => {
    // Adds extra info to all track calls
    const viewName = MemoryStorage.get(MEMORY_STORAGE.VIEW_NAME);
    const productStreamName = MemoryStorage.get(MEMORY_STORAGE.PRODUCT_STREAM_NAME);
    const version = process.env.REACT_APP_HEAD;

    context = {
      ...context,
      browserName,
      deviceType,
      template,
      isInline,
      viewName,
      productStreamName,
      version,
    };
    if (isInline) {
      context.isCarouselVisible = isElementInViewPort(document.querySelector('.adroll-rollie'));
    }
    return context;
  };

  const trackFn = getTrackFn(getPreviewModeType());

  return (action, context) => {
    return trackFn(action, _prepareTrackContext(context));
  };
};
