import Carousel from 'components/MobileRollie/Carousel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Content extends Component {
  render() {
    return (
      <div
        className="adroll-rollie-mobile-content adroll-rollie-mobile-content"
        style={this.props.appConfiguration.personalization.mobile.carousel.styles}
      >
        {this.props.tabs.map(tab => {
          return (
            <Carousel
              key={tab.id}
              tabId={tab.id}
              products={tab.products}
              dimensions={this.props.dimensions}
              selected={this.props.selectedTab.id === tab.id}
              selectedProductIndex={this.props.selectedProductIndex}
              appConfiguration={this.props.appConfiguration}
              setSelectedProductIndex={this.props.setSelectedProductIndex}
              previewModeType={this.props.previewModeType}
            />
          );
        })}
        {this.props.appConfiguration.personalization.header.customTabs.map(tab => {
          return (
            <Carousel
              key={tab.id}
              tabId={tab.id}
              products={this.props.customTabsProducts[tab.id]}
              dimensions={this.props.dimensions}
              selected={this.props.selectedTab.id === tab.id}
              selectedProductIndex={this.props.selectedProductIndex}
              setSelectedProductIndex={this.props.setSelectedProductIndex}
              appConfiguration={this.props.appConfiguration}
              previewModeType={this.props.previewModeType}
            />
          );
        })}
      </div>
    );
  }
}

Content.propTypes = {
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
  selectedProductIndex: PropTypes.number,
  setSelectedProductIndex: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
};

export default Content;
