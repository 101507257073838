import React, { Component } from 'react';

import InfoIcon from './info-icon.png';

import { isCustomizationTestModeActive, disableCustomizationTestMode } from 'utils/customization';

class TestModeBanner extends Component {
  render() {
    return (
      <div
        className={'adroll-rollie-test-mode-banner'}
        style={{
          display: isCustomizationTestModeActive() ? 'flex' : 'none',
        }}
      >
        <img src={InfoIcon} alt="" />
        <b>Previewing Carousel</b>
        <p>This is what visitors will see when you upload your carousel to your store.</p>
        <button onClick={disableCustomizationTestMode}>Return to Carousel Builder</button>
      </div>
    );
  }
}

export default TestModeBanner;
