import promise from 'es6-promise';

declare interface PreviewWindow {
  adrollRolliePreviewMode?: string;
  adrollRollieCustomizationPreview?: {
    ecommerce_platform: string,
    personalization: {
    }
  };
  adrollRolliePreferencesPreview?: {
    deviceType: string,
    identityPlusEid: string,
    sampleProducts: {
      'i/image': string,
      id: number,
      price: string,
      title: string,
      variant_ids: string
    }[],
    selectedProductIndex: number,
    viewState: string,
  };
}

declare var window: PreviewWindow & Window;

export const getPreviewModeType = () => {
  // Can be: undefined, widgetOnly, websiteIframe
  // utils/contants.PREVIEW_MODE_TYPE_WIDGET_ONLY, utils/constants.PREVIEW_MODE_TYPE_WEBSITE_IFRAME
  return window.adrollRolliePreviewMode;
};

export const getPreviewModeCustomizations = () => {
  return new promise.Promise(function(resolve, reject) {
    resolve(window.adrollRollieCustomizationPreview);
  });
};

export const getPreviewModePreferences = () => {
  return window.adrollRolliePreferencesPreview;
};

export const getPreviewModeSelectedProductIndex = () => {
  const index = getPreviewModePreferences().selectedProductIndex;
  return typeof index !== 'undefined' ? index : null;
};

export const getPreviewModeSampleProducts = () => {
  return getPreviewModePreferences().sampleProducts;
};

export const getPreviewModeIdentityPlusEid = () => {
  return getPreviewModePreferences().identityPlusEid;
};

export const getPreviewModeDeviceType = () => {
  return getPreviewModePreferences().deviceType;
};
