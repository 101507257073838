export enum ViewState {
  MINIMIZED = 'minimized',
  MAXIMIZED = 'maximized',
}

export enum PLACEMENT_OPTIONS {
  INCLUDE_ALL_PAGES = 'INCLUDE_ALL_PAGES',
  INCLUDE_SELECT_PAGES = 'INCLUDE_SELECT_PAGES',
  EXCLUDE_PATHS = 'EXCLUDE_PATHS',
}
