import { default as cx } from 'classnames';
import ProductCard from 'components/WebRollie/ProductCard';
import { default as NukaCarousel } from 'nuka-carousel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { trackCurrentlyVisibleProducts } from 'utils';
import { SLIDER_DIRECTIONS } from 'utils/constants';
import { getPreviewModeSelectedProductIndex } from 'utils/previewMode';
import { track } from '../../../index';
import LeftArrow from './LeftArrow';
import { getResponsiveSettings } from './responsiveSettings';
import RightArrow from './RightArrow';

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      direction: SLIDER_DIRECTIONS.RIGHT,
    };
  }

  render() {
    const settings = this.getResponsiveSettings();
    const needsToScroll = this.props.products.length > settings.slidesToShow;
    const showRightArrow =
      needsToScroll && this.state.currentSlide < this.props.products.length - settings.slidesToShow;
    const showLeftArrow = needsToScroll && this.state.currentSlide > 0;
    const previewModeType = this.props.previewModeType;
    let previewModeSelectedProductIndex = null;
    if (previewModeType) {
      previewModeSelectedProductIndex = getPreviewModeSelectedProductIndex();
    }
    const classNames = cx('adroll-rollie-web-carousel', {
      'adroll-rollie-web-carousel-hidden': !this.props.selected,
    });

    return (
      <div className={classNames}>
        <NukaCarousel
          speed={150}
          renderBottomCenterControls={null}
          wrapAround={false}
          renderCenterLeftControls={
            showLeftArrow
              ? ({ previousSlide }) => (
                  <LeftArrow
                    currentDirection={this.state.direction}
                    previousSlide={previousSlide}
                    appConfiguration={this.props.appConfiguration}
                  />
                )
              : null
          }
          renderCenterRightControls={
            showRightArrow
              ? ({ nextSlide }) => (
                  <RightArrow
                    currentDirection={this.state.direction}
                    nextSlide={nextSlide}
                    appConfiguration={this.props.appConfiguration}
                  />
                )
              : null
          }
          dragging={needsToScroll}
          slidesToScroll={1}
          afterSlide={this.afterSlide}
          slideIndex={this.state.currentSlide}
          disableKeyboardControls={true}
          cellAlign={'left'}
          cellSpacing={10}
          autoGenerateStyleTag={false}
          {...settings}
        >
          {this.props.products.map((product, index) => {
            return (
              <ProductCard
                key={product.id}
                product={product}
                dimensions={this.props.dimensions}
                isActive={previewModeType && previewModeSelectedProductIndex === index}
                appConfiguration={this.props.appConfiguration}
                cartData={this.props.cartData}
                previewModeType={this.props.previewModeType}
                inline={this.props.inline}
              />
            );
          })}
        </NukaCarousel>
      </div>
    );
  }

  afterSlide = currentSlide => {
    const settings = this.getResponsiveSettings();

    // Define in what direction we are in the slider currently.
    let direction = currentSlide < this.state.currentSlide ? SLIDER_DIRECTIONS.LEFT : SLIDER_DIRECTIONS.RIGHT;
    // If we are in the last slide, then the direction can only be left.
    if (currentSlide === this.props.products.length - settings.slidesToShow) {
      direction = SLIDER_DIRECTIONS.LEFT;
    } else if (currentSlide === 0) {
      direction = SLIDER_DIRECTIONS.RIGHT;
    }
    this.setState({
      currentSlide,
      direction,
    });
    trackCurrentlyVisibleProducts(track, 'ONSITE_CAROUSEL_AFTER_SLIDE');
  };

  getResponsiveSettings = () => {
    return getResponsiveSettings(
      this.props.dimensions.width,
      this.props.products.length,
      this.props.appConfiguration,
      this.props.config,
      this.props.inline
    );
  };
}

Carousel.propTypes = {
  products: PropTypes.array.isRequired,
  selected: PropTypes.bool.isRequired,
  dimensions: PropTypes.object.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  cartData: PropTypes.object,
  config: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default Carousel;
