import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { default as cx } from 'classnames';

import { filterTabs } from 'utils/filterTabs';

import TabPickerItem from 'components/ForestRollie/TabPicker/TabPickerItem';

class TabPicker extends Component {
  render() {
    if (!this.props.show) {
      return null;
    }
    const minimizeButtonClassNames = cx([
      'adroll-rollie-forest-tab-switch-buttons',
      'adroll-rollie-forest-tab-switch-minimize-button',
    ]);

    return (
      <div>
        <button
          className={minimizeButtonClassNames}
          onClick={this.props.toggleViewState}
          style={this.props.appConfiguration.personalization.mobile.header.styles}
        >
          — Minimize
        </button>
        {this.props.tabs
          .filter(
            // We apply the same filters as in the
            // `RollieContainer.componentDidMount` tosupport dynamic toggling
            // of the tab. We also filter out the currently selected tab
            tab =>
              filterTabs(tab, this.props.appConfiguration.personalization.header.enabledTabs) &&
              tab !== this.props.selectedTab
          )
          .map(tab => (
            <TabPickerItem
              key={tab.id}
              tab={tab}
              setSelectedTab={this.props.setSelectedTab}
              appConfiguration={this.props.appConfiguration}
            />
          ))}
        {this.props.appConfiguration.personalization.header.customTabs
          .filter(tab => {
            return this.props.customTabsProducts[tab.id] && tab.enabled;
          })
          .map(tab => {
            return (
              <TabPickerItem
                key={tab.id}
                tab={tab}
                setSelectedTab={this.props.setSelectedTab}
                appConfiguration={this.props.appConfiguration}
              />
            );
          })}
      </div>
    );
  }
}

TabPicker.propTypes = {
  show: PropTypes.bool.isRequired,
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  toggleViewState: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
};

export default TabPicker;
