import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ShoppingBagIcon from './ShoppingBagIcon';
import ShoppingCartIcon from './ShoppingCartIcon';
import ShoppingCartNumber from './ShoppingCartNumber';

const iconMap = {
  cart: ShoppingCartIcon,
  bag: ShoppingBagIcon,
};

class ShoppingCart extends Component {
  render() {
    const { cartItemsQuantity, onClick } = this.props;
    const appCustomizations = this.props.appConfiguration.personalization;
    const IconComponent = iconMap[appCustomizations.shoppingCart.iconName];
    return (
      <div className="adroll-rollie-cart adroll-rollie-mobile-cart" onClick={onClick}>
        <IconComponent style={{ fill: appCustomizations.header.styles.color }} />
        <ShoppingCartNumber cartItemsQuantity={cartItemsQuantity} style={{}} />
      </div>
    );
  }
}

ShoppingCart.propTypes = {
  appConfiguration: PropTypes.object.isRequired,
  cartItemsQuantity: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default ShoppingCart;
