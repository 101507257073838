import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { track } from '../../../index';
import { ATTRIBUTION_TRACKING } from 'utils/constants';
import { navigate } from 'utils/window';

class DetailsButton extends Component {
  handleClick = e => {
    const { idhash, variant_ids, url, source } = this.props.product;
    track(ATTRIBUTION_TRACKING.ONSITE_PRODUCT_DETAILS, {
      productId: idhash,
      variantId: variant_ids,
      source,
    });

    // Don't navigate to the product page if we are in preview mode.
    if (!this.props.previewModeType) {
      navigate(url);
    }
  };

  render() {
    return (
      <button className="adroll-rollie-mobile-details-button" onClick={this.handleClick}>
        Details...
      </button>
    );
  }
}

DetailsButton.propTypes = {
  product: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
};

export default DetailsButton;
