import { PlacementType } from '../../constants';
import { handleGlobal, handleIncluded, isCurrPageExcluded } from './handler';
import { shopifyPageDetection } from './pageDetection';

const DEFAULT_PLATFORM = 'shopify';
/**
 * The rules are
 * a) First check if the current page is excluded.  If so indicate as so using PlacementType.NONE
 * b) If we are not able to detect current page, then handle the global scenario resolver
 * c) If the current page is in the included set, find the appropriate resolver
 * d) Fall back scenario is to us the global scenario resolver
 */
export default (appConfiguration, deviceType) => {
  if (appConfiguration.ecommerce_platform !== DEFAULT_PLATFORM) {
    return;
  }
  if (
    !appConfiguration ||
    !appConfiguration.noBuilderCustomizations ||
    !appConfiguration.noBuilderCustomizations[`${deviceType}`]
  ) {
    return undefined;
  }
  const placementsV2 = appConfiguration.noBuilderCustomizations[`${deviceType}`].placementsV2;
  if (!placementsV2 || !placementsV2.excludePages || !placementsV2.includePages || !placementsV2.global) {
    return;
  }

  const detectedPageKeys = Object.keys(shopifyPageDetection).filter(k => shopifyPageDetection[k]());
  const { global, includePages, excludePages } = placementsV2;
  if (detectedPageKeys.length !== 1) {
    return handleGlobal(global);
  }

  const currPageKey = detectedPageKeys[0];
  if (isCurrPageExcluded(excludePages, currPageKey)) {
    return { type: PlacementType.NONE };
  }

  const included = handleIncluded(currPageKey, includePages);
  if (included) {
    return included;
  }

  return handleGlobal(global);
};
