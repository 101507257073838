import { getHref, getPathName } from 'utils/window';

export const shopifyPageDetection = {
  home: () => getPathName() === '/',
  product: () => getHref().indexOf('/products/') !== -1 || getHref().indexOf('/product/') !== -1,
  cart: () => getPathName().indexOf('/cart') !== -1,
  collection: () => getHref().indexOf('/product') === -1 && getPathName().indexOf('/collection') !== -1,
  search: () => getPathName().indexOf('/search') !== -1,
  blog: () => getPathName().indexOf('/blog') !== -1,
  404: () => getPathName().indexOf('/404') !== -1,
  conversion: () =>
    typeof window !== 'undefined' &&
    typeof window.Shopify !== 'undefined' &&
    window.Shopify.Checkout !== undefined &&
    window.Shopify.Checkout.page !== undefined &&
    window.Shopify.Checkout.page === 'thank_you',
};
