import { DimensionState } from '../common/interfaces';
import { getAddToCartHandler } from './index';

export const isDnd = (dimensions: DimensionState) => {
  return dimensions.width <= 600;
};

export const canAddToCart = (variantIds, appConfiguration) => {
  return (
    appConfiguration &&
    appConfiguration.noBuilderCustomizations.features.addToCart &&
    variantIds &&
    variantIds.split(',').length === 1 &&
    getAddToCartHandler(appConfiguration.ecommerce_platform) !== null
  );
};

export const isMobile = (config: any, dimensions: any) => {
  const { deviceType } = config;
  return deviceType === 'mobile';
};
