import { getAppPlacementTheme } from '../../../utils/placements/placementMapping';

const THRESHOLDS = {
  440: 1.5,
  750: 2.5,
  975: 3.5,
  1150: 4.5,
  1400: 5.5,
  1675: 6.5,
  2200: 7.5,
  Infinity: 8.5,
};

export const getResponsiveSettings = (screenWidth, productCount, appConfiguration, config, inline) => {
  let count;
  for (const width of Object.keys(THRESHOLDS)) {
    if (screenWidth < width) {
      count = THRESHOLDS[width];
      break;
    }
  }

  count *= inline ? 0.85 : 1;
  const limtByCardsToShow =
    appConfiguration &&
    appConfiguration.noBuilderCustomizations &&
    appConfiguration.noBuilderCustomizations.features &&
    appConfiguration.noBuilderCustomizations.features.cardsToShow &&
    !isNaN(parseInt(appConfiguration.noBuilderCustomizations.features.cardsToShow, 10));
  const SLIDES_TO_SHOW = limtByCardsToShow
    ? parseInt(appConfiguration.noBuilderCustomizations.features.cardsToShow, 10)
    : count;

  count = Math.min(SLIDES_TO_SHOW, count);
  if (productCount >= count) return { slidesToShow: count, slideWidth: 1 };

  const cellAlign = getAlignment(appConfiguration, config, limtByCardsToShow);

  return { slideWidth: 1 / SLIDES_TO_SHOW, cellAlign };
};

const getAlignment = (appConfiguration, config, limtByCardsToShow) => {
  const { personalization } = appConfiguration;
  const theme = getAppPlacementTheme(personalization, config);
  if (personalization.product.align[theme]) {
    return personalization.product.align[theme];
  }
  return limtByCardsToShow ? 'center' : 'left';
};
