const scaleFactor = inline => (inline ? 1.5 : 1.25);
const DEFAULT_EM_PIXELS = 16;
export const getWebProductCardDimensions = (appConfiguration, inline) => {
  const defaultHeight = inline ? { width: 200, height: 250 } : { height: 130 };
  return appConfiguration.personalization.product.styles.height || defaultHeight;
};

export const getWebCarouselHeight = (appConfiguration, inline) => {
  const fontSize = appConfiguration.personalization.product.styles.fontSize;
  const calculation = getWebProductCardDimensions(appConfiguration, inline).height - 2 * fontSize;
  return calculation;
};

export const getWebContentHeight = (appConfiguration, inline) => {
  const fontSize = appConfiguration.personalization.product.styles.fontSize;
  return (
    scaleFactor(inline) * (fontSize / DEFAULT_EM_PIXELS) + getWebProductCardDimensions(appConfiguration, inline).height
  );
};
