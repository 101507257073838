import { DEFAULT_DESKTOP_PLACEMENT, DEFAULT_MOBILE_PLACEMENT, PlacementType } from '../constants';
import addFixedFooter from './addFixedFooter';
import addInline from './addInline';
import getThemeConfig from './getThemeConfig';
import { getAppPlacementTheme } from './placementMapping';
import shopifyV2 from './shopify';

export default (platform: string, path: string, appConfiguration: any, config: any) => {
  const shopifyV2Result = shopifyV2(appConfiguration, config.deviceType);
  if (shopifyV2Result) {
    // If it is not a shopify platform or there is no placementsV2, then it is not handled ad we fall back to V1 implementation
    if (shopifyV2Result === PlacementType.NONE) {
      return undefined;
    }
    return shopifyV2Result;
  }
  const { personalization, theme } = get(appConfiguration, config);
  const themeConfig = getThemeConfig(personalization, theme, platform, path);

  if (!themeConfig || themeConfig === PlacementType.NONE || themeConfig.type === PlacementType.NONE) {
    return undefined;
  }

  if (themeConfig.type === PlacementType.FIXED) {
    return addFixedFooter();
  }
  if (!themeConfig || !themeConfig.placement || !themeConfig.placement.selectors) {
    return undefined;
  }

  return addInline(themeConfig);
};

const get = (appConfiguration, config) => {
  let theme = config.deviceType === 'desktop' ? DEFAULT_DESKTOP_PLACEMENT : DEFAULT_MOBILE_PLACEMENT;
  const { personalization } = appConfiguration;
  if (personalization && personalization.placements) {
    theme = getAppPlacementTheme(personalization, config);

    return {
      theme,
      personalization,
    };
  }
  return {
    theme: config.deviceType === 'desktop' ? DEFAULT_DESKTOP_PLACEMENT : DEFAULT_MOBILE_PLACEMENT,
    personalization: {
      placements: { overrides: [] },
    },
  };
};
