import React from 'react';

interface PollerState {
  data: any;
}
interface PollerProps {
  render: (x: PollerState) => JSX.Element;
  func: () => Promise<any>;
  interval: number;
  errorLimit: number;
}

/*
 * Need to use both window.document.hidden flag and the focus / blur event.
 * The focus / blur event is not consistent with deregistering the interval while the window.document.hidden flag works in this use case.
 * However, window.document.hidden flag does not work in the scenario when the tab is inactive / hidden when the widget loads.
 * In this case, polling never starts once it becomes active
 */
class Poller extends React.Component<PollerProps, PollerState> {
  timer: any;
  retryCount: number;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.start();

    window.addEventListener('focus', () => this.start());
    window.addEventListener('blur', () => this.reset());
  }

  canDisplay = () => {
    return this.props.interval > 0 && !window.document.hidden;
  };
  start = () => {
    if (this.canDisplay()) {
      this.myFunc();
      // Start timer
      this.timer = setInterval(() => this.myFunc(), this.props.interval);
      this.retryCount = 0;
    }
  };

  reset = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = 0;
      this.retryCount = 0;
    }
  };
  componentWillUnmount() {
    this.reset();
  }
  render() {
    return <>{this.props.render(this.state)}</>;
  }

  myFunc = () => {
    const { func, errorLimit, interval } = this.props;
    if (!this.canDisplay() || !func) {
      return;
    }

    // @ts-ignore
    return func()
      .then(data => this.setState({ data }))
      .catch(err => {
        this.retryCount++;
        if (this.retryCount >= errorLimit) {
          this.reset();
        }
      });
  };
}

export default Poller;
