import AddToCartButton from 'components/MobileRollie/AddToCartButton';
import DetailsButton from 'components/MobileRollie/DetailsButton';
import HeaderMenu from 'components/MobileRollie/HeaderMenu';
import MinimizeButton from 'components/MobileRollie/MinimizeButton';
import ShoppingCart from 'components/MobileRollie/ShoppingCart';
import ShoppingCartContainer from 'containers/ShoppingCartContainer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Header extends Component {
  renderProductSelectedMenu = () => {
    return (
      <div
        className={'adroll-rollie-mobile-header'}
        style={this.props.appConfiguration.personalization.mobile.header.styles}
      >
        <div className={'adroll-rollie-header-product-title'}>{this.props.selectedProduct.title}</div>
        <DetailsButton product={this.props.selectedProduct} previewModeType={this.props.previewModeType} />
        <AddToCartButton
          product={this.props.selectedProduct}
          appConfiguration={this.props.appConfiguration}
          cartData={this.props.cartData}
        />
      </div>
    );
  };

  renderProductUnselectedMenu = () => {
    return (
      <div
        className={'adroll-rollie-mobile-header'}
        style={this.props.appConfiguration.personalization.mobile.header.styles}
      >
        <HeaderMenu
          tabs={this.props.tabs}
          customTabsProducts={this.props.customTabsProducts}
          selectedTab={this.props.selectedTab}
          setSelectedTab={this.props.setSelectedTab}
          appConfiguration={this.props.appConfiguration}
          useMobileCssClass={true}
        />

        {!this.props.inline && (
          <>
            {this.props.appConfiguration.noBuilderCustomizations.features.showCartLink && (
              <ShoppingCartContainer
                renderWhenEmpty={false}
                appConfiguration={this.props.appConfiguration}
                shoppingCartComponent={ShoppingCart}
                cartData={this.props.cartData}
                previewModeType={this.props.previewModeType}
              />
            )}

            <MinimizeButton
              onClick={this.props.toggleViewState}
              appConfiguration={this.props.appConfiguration}
              useMobileCssClass={true}
            />
          </>
        )}
      </div>
    );
  };

  render() {
    if (this.props.selectedProduct !== null) {
      return this.renderProductSelectedMenu();
    }
    return this.renderProductUnselectedMenu();
  }
}

Header.propTypes = {
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  toggleViewState: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  cartData: PropTypes.object,
  config: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default Header;
