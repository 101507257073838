import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ATTRIBUTION_TRACKING } from 'utils/constants';
import { track } from '../index';
import { navigate } from '../utils/window';

class ShoppingCartContainer extends Component {
  getCartQuantity = (isPreviewModeActiveWithSomePlatform = false) => {
    // If we are in preview mode, let's show the cart quantity as 1 so the user
    // can see how it will look like.
    if (isPreviewModeActiveWithSomePlatform) {
      return 1;
    }
    if (this.props.cartData && this.props.cartData.data && this.props.cartData.data.itemsCount) {
      return this.props.cartData.data.itemsCount;
    }
    return 0;
  };
  render() {
    const ShoppingCartComponent = this.props.shoppingCartComponent;
    const onClick = this.getOnClickCallBack();
    const isPreviewModeActiveWithSomePlatform =
      this.props.previewModeType && this.props.appConfiguration.ecommerce_platform;
    if (!this.props.renderWhenEmpty && this.getCartQuantity(isPreviewModeActiveWithSomePlatform) === 0) {
      return null;
    }
    // If we don't have onClick event, it means that we don't know
    // where to send the user in case he clicks in the cart icon.
    // It also means that makes no sense to show the shopping cart icon.
    if (!onClick && !isPreviewModeActiveWithSomePlatform) {
      return null;
    }

    return (
      <ShoppingCartComponent
        appConfiguration={this.props.appConfiguration}
        cartItemsQuantity={this.getCartQuantity(isPreviewModeActiveWithSomePlatform)}
        onClick={onClick}
      />
    );
  }

  getShoppingCartUrl = () => {
    return (this.props.cartData && this.props.cartData.data && this.props.cartData.data.cartUrl) || null;
  };

  getOnClickCallBack = () => {
    // We only have an onClick callback if we know the shopping cart url
    // to redirect the user.
    return this.getShoppingCartUrl() !== null ? this.onClick : null;
  };

  onClick = () => {
    track(ATTRIBUTION_TRACKING.ONSITE_GOTO_CART);
    navigate(this.getShoppingCartUrl());
  };
}

ShoppingCartContainer.propTypes = {
  renderWhenEmpty: PropTypes.bool.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  shoppingCartComponent: PropTypes.func.isRequired, // This is for receiving a component class (not an instance)
  cartData: PropTypes.object,
  previewModeType: PropTypes.string,
};

export default ShoppingCartContainer;
