import React from 'react';
import { DimensionState } from './common/interfaces';
import { debounce } from './utils/debounce';
import { calculateHeight } from './utils/window';

interface DimensionProps {
  inlineElement?: Element;
  referenceNode?: Element;
  inline?: boolean;
  appConfiguration?: any;
  insertBeforeElem?: boolean;
  render: (x: DimensionState) => JSX.Element;
}

class Dimensions extends React.Component<DimensionProps, DimensionState> {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, refNodeHeight: 0, insertBeforeElem: true };
  }

  updateMargins = () => {
      const {inline, inlineElement, referenceNode, insertBeforeElem, appConfiguration} = this.props;
      if (!inline) {
          return
      }
      const refNodeHeight = calculateHeight(referenceNode || inlineElement);

      this.setState({
          insertBeforeElem,
          refNodeHeight
      })
  }
  updateDimensions = () => {
    const { inlineElement } = this.props;
    let width = 0;
    let height = 0;
    if (!inlineElement) {
      width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
    } else {
      const rect = inlineElement.getBoundingClientRect();
      height = rect.height;
      width = rect.width;
    }
    
    this.setState({
      width: width || window.innerWidth,
      height,
    });
  };

  update = () => {
      this.updateDimensions()
      this.updateMargins()
  }
  componentDidMount() {
    this.update()
    window.addEventListener('resize', debounce(this.update, 100));
  }

  componentWillMount() {
    window.removeEventListener('resize', debounce(this.update, 100));
  }
  render() {
    return <>{this.props.render(this.state)}</>;
  }
}

export default Dimensions;
