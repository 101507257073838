import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { filterTabs } from 'utils/filterTabs';
import HeaderMenuItem from './HeaderMenuItem';

class HeaderMenu extends Component {
  render() {
    return (
      <div className="adroll-rollie-mobile-header-menu">
        {/*
        We apply the same filters as in the `RollieContainer.componentDidMount` to
        support dynamic toggling of the tab
      */}
        {this.props.tabs
          .filter(tab => filterTabs(tab, this.props.appConfiguration.personalization.header.enabledTabs))
          .map(tab => {
            return (
              <HeaderMenuItem
                key={tab.id}
                tab={tab}
                selected={tab.id === this.props.selectedTab.id}
                setSelectedTab={this.props.setSelectedTab}
                appConfiguration={this.props.appConfiguration}
              />
            );
          })}
        {this.props.appConfiguration.personalization.header.customTabs
          .filter(tab => {
            return this.props.customTabsProducts[tab.id] && tab.enabled;
          })
          .map(tab => {
            return (
              <HeaderMenuItem
                key={tab.id}
                tab={tab}
                selected={tab.id === this.props.selectedTab.id}
                setSelectedTab={this.props.setSelectedTab}
                appConfiguration={this.props.appConfiguration}
                title={tab.title}
              />
            );
          })}
      </div>
    );
  }
}

HeaderMenu.propTypes = {
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
};

export default HeaderMenu;
