import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShoppingCartNumber extends Component {
  render() {
    const { cartItemsQuantity } = this.props;

    // Don't display the number icon if there are no items in the cart
    if (cartItemsQuantity === 0) {
      return null;
    }

    return (
      <span className="adroll-rollie-cart-number" style={this.props.style}>
        {cartItemsQuantity}
      </span>
    );
  }
}

ShoppingCartNumber.propTypes = {
  cartItemsQuantity: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

export default ShoppingCartNumber;
