export const ItemTypes = {
  ITEM: 'product-card-item',
};

export const MEDIA_QUERY_THEMES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  FAT_PHONE: 'large-phone',
  THIN_PHONE: 'phone',
  REGULAR_PHONE: 'regular-phone',
  REGULAR_IPHONE: 'regular-iphone',
};

export const SELECTED_VIEW = {
  MOST_POPULAR: 'MOST POPULAR',
  RECOMMENDATIONS: 'TOP PICKS FOR YOU',
  RECENTLY_VIEWED: 'RECENTLY VIEWED',
};

export const ATTRIBUTION_TRACKING = {
  ONSITE_MINIMIZE: 'ONSITE_MINIMIZE',
  ONSITE_EXPAND: 'ONSITE_EXPAND',
  ONSITE_IMPRESSION: 'ONSITE_IMPRESSION',
  ONSITE_PRODUCT_CLICK: 'ONSITE_PRODUCT_CLICK',
  ONSITE_PRODUCT_SELECT: 'ONSITE_PRODUCT_SELECT',
  ONSITE_PRODUCT_DESELECT: 'ONSITE_PRODUCT_DESELECT',
  ONSITE_PRODUCT_DETAILS: 'ONSITE_PRODUCT_DETAILS',
  ONSITE_PRODUCTS_VIEWED: 'ONSITE_PRODUCTS_VIEWED',
  ONSITE_ADD_TO_CART: 'ONSITE_ADD_TO_CART',
  ONSITE_GOTO_CART: 'ONSITE_GOTO_CART',
  ONSITE_NEXT_ARROW: 'ONSITE_NEXT_ARROW',
  ONSITE_PREV_ARROW: 'ONSITE_PREV_ARROW',
  ONSITE_CAROUSEL_SWIPE: 'ONSITE_CAROUSEL_SWIPE',
  ONSITE_CHANGE_RECOMMENDATION_TYPE: 'ONSITE_CHANGE_RECOMMENDATION_TYPE',
  ONSITE_CURRENT_VIEW: 'ONSITE_CURRENT_VIEW',
  ONSITE_RECOMMENDED_PRODUCTS_LOAD_FAILED: 'ONSITE_RECOMMENDED_PRODUCTS_LOAD_FAILED',
  ONSITE_TOP_PRODUCTS_LOAD_FAILED: 'ONSITE_TOP_PRODUCTS_LOAD_FAILED',
  ONSITE_TAB_SWITCH: 'ONSITE_TAB_SWITCH',
  ONSITE_CUSTOM_TABS_PRODUCTS_LOAD_FAILED: 'ONSITE_CUSTOM_TABS_PRODUCTS_LOAD_FAILED',
};

export const PRODUCT_COLLECTIONS = {
  recommendations: {
    viewName: 'For You',
    productKey: 'recommendedProducts',
  },
  identity: {
    viewName: 'Recents',
    productKey: 'identityProducts',
  },
  top_products: {
    viewName: 'Popular',
    productKey: 'topProducts',
  },
};

export const APP_STATE_KEY = 'adroll-app-state';
export const LOCAL_STORAGE_PRODUCT_STREAM_KEY = 'product_stream_eid';

export const CACHE_ROOT_DOMAIN: string = `https://s.adroll.com`;

export const SLIDER_DIRECTIONS = {
  RIGHT: 'right',
  LEFT: 'left',
};

export const CART_API_POLL_INTERVAL = 5000;
export const DO_NOT_POLL = -1;

export const SAFE_ORIGINS_MAP = {
  development: 'http://localhost:3001',
  production: 'https://app.adroll.com',
  staging: 'https://staging.internal.adroll.com',
};

export const PLATFORM_SHOPIFY = 'shopify';
export const PLATFORM_BIGCOMMERCE = 'bigcommerce';
export const PLATFORM_MAGENTO = 'magento';
export const PLATFORM_MAGENTO2 = 'magento2';

export enum PlacementType {
  INLINE = 'inline',
  FIXED = 'fixed',
  NONE = 'none',
  INLINE_ABOVE_FOOTER = 'abovefooter',
}
export const NO_BUILDER_CUSTOMIZATIONS_FILE_NAME = 'no_builder_customizations';
export const CONFIGURATION_FILE_NAME = 'config';

export const DEFAULT_DESKTOP_PLACEMENT = 'fixedfooter';
export const DEFAULT_MOBILE_PLACEMENT = 'fixedfooter';

export const PREVIEW_MODE_TYPE_WIDGET_ONLY = 'widgetOnly';
export const PREVIEW_MODE_TYPE_WEBSITE_IFRAME = 'websiteIframe';

export const MEMORY_STORAGE = {
  PRODUCT_STREAM_NAME: 'PRODUCT_STREAM_NAME',
  VIEW_NAME: 'VIEW_NAME',
};
