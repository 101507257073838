import TestModeBanner from 'components/common/TestModeBanner';
import ForestRollie from 'components/ForestRollie';
import MobileRollie from 'components/MobileRollie';
import TeeRollie from 'components/TeeRollie';
import WebRollie from 'components/WebRollie';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getProductStream } from 'utils/api/adroll';
import { shuffle } from 'utils/array';
import {
  ATTRIBUTION_TRACKING,
  CART_API_POLL_INTERVAL,
  DEFAULT_MOBILE_PLACEMENT,
  DO_NOT_POLL,
  MEMORY_STORAGE,
  PRODUCT_COLLECTIONS,
} from 'utils/constants';
import { ViewState } from 'utils/enum';
import { addMarginBottomToBody, removeMarginBottomFromBody } from 'utils/index';
import { isMobile } from 'utils/isDnd';
import { mapVisibleProductsToTrackBody } from 'utils/mapper';
import { Preferences } from 'utils/preferences';
import {
  getPreviewModeIdentityPlusEid,
  getPreviewModeSampleProducts,
  getPreviewModeSelectedProductIndex,
} from 'utils/previewMode';
import { showWaterMark } from 'utils/showWaterMark';
import { getWebProductCardDimensions } from 'utils/webContentScaling';
import { track } from '../index';
import Poller from '../Poller';
import { getCartFunction, trackCurrentlyVisibleProducts } from '../utils';
import { filterTabs } from '../utils/filterTabs';
import later from '../utils/later';
import { getAppPlacementTheme } from '../utils/placements/placementMapping';
import { MemoryStorage } from '../utils/storage';
import {
  addEventListener,
  dispatchResizeEvent,
  dispatchWindowEvent,
  getAttributes,
  getPathName,
  removeEventListener,
} from '../utils/window';

class RollieContainer extends Component {
  constructor(props) {
    super(props);

    const tabs = Object.keys(PRODUCT_COLLECTIONS).map(collection => ({
      id: PRODUCT_COLLECTIONS[collection].productKey,
      title: PRODUCT_COLLECTIONS[collection].viewName,
      products: [],
      customizationName: collection,
    }));

    this.state = {
      tabs,
      customTabsProducts: {},
      selectedTab: null,
      selectedProductIndex: null,
      viewState: ViewState.MAXIMIZED,
      ready: false,
      didAutoMaximize: false,
    };
  }

  componentDidMount() {
    const { previewModeType } = this.props;

    if (previewModeType) {
      this.setSelectedProductIndex(getPreviewModeSelectedProductIndex());
    }

    let {
      config: { productStreamEid },
    } = this.props;

    const {
      config: { advertisableEid },
    } = this.props;

    if (previewModeType) {
      productStreamEid = getPreviewModeIdentityPlusEid();
    }

    let preferredViewState = Preferences.getViewState();
    if (this.props.inline) {
      preferredViewState = ViewState.MAXIMIZED;
      this.setState({ viewState: preferredViewState, didAutoMaximize: false });
    } else {
      if (typeof preferredViewState === 'undefined') {
        // If we don't have any user preferences, then we assume the default view
        // sate of MINIMIZED, and add a scroll listener to the page that will
        // expand the widget automatically after they scroll a certain distance
        // from the top.
        preferredViewState = ViewState.MINIMIZED;
        this.addScrollListener();
      } else {
        // If we DO have user preferences, we make sure to toggle the widget to match the preference settings.
        if (this.state.viewState !== preferredViewState) {
          // Pass in true for the 'auto' arg to signal that this is an automatic
          // action not initiated by the user.
          this.toggleViewState(true);
        }
      }
    }

    return getProductStream(advertisableEid, this.props.appConfiguration, productStreamEid, track).then(
      productStream => {
        const tabs = this.state.tabs.map(tab => {
          // Assign products to the tabs, filtering out products that have null,
          // undefined or empty i/image and f/image properties.
          tab.products = productStream[tab.id]
            .filter(product => Boolean(product.image))
            .map(product => {
              product['i/image'] = `https://t2.adroll.com/${product.advertisable}/${product.idhash}/${0}/min,500/?url=${
                product.image
              }`;
              return product;
            });
          if (previewModeType) {
            const sampleProducts = getPreviewModeSampleProducts();
            if (sampleProducts !== undefined) {
              tab.products = tab.products.concat(sampleProducts);
            }
          }
          return tab;
        });
        const customTabsProducts = {};
        this.props.appConfiguration.personalization.header.customTabs.forEach(tab => {
          let products = productStream.customTabsProducts[tab.id]
            .filter(product => Boolean(product.image))
            .map(product => {
              product['i/image'] = `https://t2.adroll.com/${product.advertisable}/${product.idhash}/${0}/min,500/?url=${
                product.image
              }`;
              return product;
            });
          if (tab.arrangement === 'random') {
            shuffle(products);
          } else if (tab.arrangement === 'custom') {
            const productOrder = tab.selectedProducts || [];
            products.sort((a, b) => {
              const indexA = productOrder.findIndex(p => p.id === a.id);
              const indexB = productOrder.findIndex(p => p.id === b.id);
              return indexA - indexB;
            });
          }

          if (previewModeType) {
            const sampleProducts = getPreviewModeSampleProducts();
            if (sampleProducts !== undefined) {
              products = products.concat(sampleProducts);
            }
          }
          customTabsProducts[tab.id] = products;
        });

        const enabledTabs = tabs.filter(tab =>
          filterTabs(tab, this.props.appConfiguration.personalization.header.enabledTabs)
        );
        // Get selected tab from the preferences or from the first enabled tab
        const selectedTab = this.getSelectedTab(
          enabledTabs,
          this.props.appConfiguration.personalization.header.customTabs
        );
        const productStreamName = productStream.meta.product_stream_name;
        const viewName = selectedTab ? selectedTab.id : 'unknown_tab';

        MemoryStorage.set(MEMORY_STORAGE.VIEW_NAME, viewName);
        MemoryStorage.set(MEMORY_STORAGE.PRODUCT_STREAM_NAME, productStreamName);

        const ready = enabledTabs.length > 0 || Object.keys(customTabsProducts).length > 0;
        this.setState({
          // We are ready to render if we have at least one tab that is enabled and has products
          ready,
          tabs,
          customTabsProducts,
          selectedTab,
          productStreamName,
        });
        // At this point, preferredViewState is the view state that will be used
        // for the initial display of the widget.
        track(ATTRIBUTION_TRACKING.ONSITE_IMPRESSION, {
          advertisableEid: this.props.config.advertisableEid,
          pixelId: this.props.config.pixelId,
          viewState: preferredViewState,
          readyToDisplay: ready,
        });

        if (preferredViewState === ViewState.MAXIMIZED && ready) {
          trackCurrentlyVisibleProducts(track, ATTRIBUTION_TRACKING.ONSITE_IMPRESSION, 1000);
        }

        if (typeof window.adrollRollieContainerMounted === 'function') {
          // callback function to signal that the widget is done loading.
          window.adrollRollieContainerMounted();
        }
      }
    );
  }

  componentWillUnmount() {
    this.removeScrollListener();
  }

  getSelectedTab = (enabledTabs, customTabs) => {
    const { previewModeType } = this.props;
    // Find the POPULAR tab if we're in preview mode.
    // Otherwise find the last tab the user had selected.
    const preferredTab = enabledTabs.find(
      tab => tab.id === (previewModeType ? 'topProducts' : Preferences.getSelectedTabId())
    );
    if (preferredTab) {
      return preferredTab;
    }
    // If no tab could be found, default to using the first non-empty tab.
    return enabledTabs[0] || customTabs.find(t => t.enabled);
  };

  getFilteredTabData = (tab, cartData) => {
    if (!cartData || !cartData.data || !cartData.data.items || cartData.data.items.length === 0) {
      return tab;
    }
    const cartProducts = {};
    for (let i = 0; i < cartData.data.items.length; i++) {
      cartProducts[`${cartData.data.items[i]}`] = 1;
    }
    return {
      ...tab,
      products: tab.products ? tab.products.filter(p => cartProducts[p.id] === undefined) : [],
    };
  };

  getRollie() {
    const theme = getAppPlacementTheme(this.props.appConfiguration.personalization, this.props.config);
    if (!isMobile(this.props.config, this.props.dimensions)) {
      switch (this.props.appConfiguration.personalization.template) {
        case 'tee':
          return TeeRollie;
        default:
          return WebRollie;
      }
    } else {
      return theme === DEFAULT_MOBILE_PLACEMENT &&
        this.props.appConfiguration.personalization.mobileTemplate === 'forest'
        ? ForestRollie
        : MobileRollie;
    }
  }

  getStyles = () => {
    const { previewModeType, appConfiguration } = this.props;

    // This hides the widget using "display: none" until the builder finishes
    // loading and sends the first set of customizations.
    // We do this to prevent the widget from showing incorrect customizations
    // when the builder loads.
    // This should have no effect on the non-preview widgets.
    let display = 'block';
    if (previewModeType && !this.props.receivedPreviewModeBuilderCustomizations) {
      display = 'none';
    }
    if (!this.props.inline) {
      return { display };
    }
    const { insertBeforeElem, refNodeHeight } = this.props.dimensions;
    const dimensionStyles = insertBeforeElem ? { marginBottom: refNodeHeight } : { marginTop: refNodeHeight };

    // 35 is the height of header + margin above and 32 is height of the watermark
    const height =
      getWebProductCardDimensions(appConfiguration, true).height + 35 + (showWaterMark(appConfiguration) ? 32 : 0);
    const styles = {
      display,
      ...dimensionStyles,
      height,
    };

    return styles;
  };
  render() {
    if (!this.state.ready) {
      return null;
    }

    const { previewModeType, appConfiguration } = this.props;
    return (
      <Poller
        interval={previewModeType ? DO_NOT_POLL : CART_API_POLL_INTERVAL}
        errorLimit={3}
        // @ts-ignore
        func={getCartFunction(appConfiguration.ecommerce_platform)}
        render={data => {
          const tabs = this.state.tabs.map(t => this.getFilteredTabData(t, data));
          const selectedTab = this.getFilteredTabData(this.state.selectedTab, data);
          const Rollie = this.getRollie();

          return (
            <div style={this.getStyles()} className="rollie-container">
              <Rollie
                tabs={tabs}
                customTabsProducts={this.state.customTabsProducts}
                selectedTab={selectedTab}
                setSelectedTab={this.setSelectedTab}
                selectedProductIndex={this.state.selectedProductIndex}
                setSelectedProductIndex={this.setSelectedProductIndex}
                viewState={this.state.viewState}
                toggleViewState={this.toggleViewState}
                appConfiguration={this.props.appConfiguration}
                cartData={data}
                previewModeType={previewModeType}
                inline={this.props.inline}
                {...this.props}
              />
              <TestModeBanner />
            </div>
          );
        }}
      />
    );
  }

  setSelectedTab = (tab, auto = false) => {
    this.setState({ selectedTab: tab });
    const viewName = tab.id;
    Preferences.setSelectedTabId(viewName);
    MemoryStorage.set(MEMORY_STORAGE.VIEW_NAME, viewName);

    if (!auto) {
      // ignore attribution tracking if this was an automatic tab switch
      track(ATTRIBUTION_TRACKING.ONSITE_TAB_SWITCH, {
        products: mapVisibleProductsToTrackBody(
          tab.collectionId || tab.selectedProducts ? this.state.customTabsProducts[tab.id] : tab.products
        ),
        viewState: Preferences.getViewState(),
      });
      trackCurrentlyVisibleProducts(track, ATTRIBUTION_TRACKING.ONSITE_TAB_SWITCH);
    }

    // This is needed because of a "bug" with this carousel. Without this, the
    // carousel will fail to render correctly when changing tabs.
    // https://github.com/FormidableLabs/nuka-carousel/issues/103

    return dispatchWindowEvent('resize', 0);
  };

  setSelectedProductIndex = productIndex => {
    this.setState({ selectedProductIndex: productIndex });
  };

  toggleViewState = async (auto = false) => {
    const newViewState = this.state.viewState === ViewState.MINIMIZED ? ViewState.MAXIMIZED : ViewState.MINIMIZED;

    // Turn off the auto maximize after viewState has been toggled once
    this.setState({ viewState: newViewState, didAutoMaximize: true });
    Preferences.setViewState(newViewState);

    // Adjust page body to accomodate the widget
    let trackAction = null;
    if (newViewState === ViewState.MINIMIZED) {
      removeMarginBottomFromBody();
      trackAction = ATTRIBUTION_TRACKING.ONSITE_MINIMIZE;
    } else {
      await later(500, addMarginBottomToBody);
      trackAction = ATTRIBUTION_TRACKING.ONSITE_EXPAND;
      if (!auto) {
        trackCurrentlyVisibleProducts(track, ATTRIBUTION_TRACKING.ONSITE_EXPAND, 500);
      }
    }
    track(trackAction, { auto });
    dispatchResizeEvent();
  };

  addScrollListener = () => {
    addEventListener('scroll', this.autoMaximize);
  };

  removeScrollListener = () => {
    removeEventListener('scroll', this.autoMaximize);
  };

  getCurrentPage = () => {
    const pathname = getPathName();

    if (pathname === '/') {
      return 'home';
    }
    if (pathname.indexOf('/products/') !== -1) {
      return 'product';
    }
    if (pathname.indexOf('/cart') !== -1) {
      return 'cart';
    }
  };

  autoMaximize = () => {
    const customizations = this.props.appConfiguration.personalization;
    const expandInThisPage = customizations.minimizedLabel.expandOnPages.indexOf(this.getCurrentPage()) !== -1;
    if (getAttributes().pageYOffset > 400 && !this.state.didAutoMaximize && expandInThisPage) {
      const delayExpanding = customizations.minimizedLabel.delayExpanding;
      if (delayExpanding === 0) {
        this.toggleViewState(true);
      } else {
        const expandInterval = setInterval(() => {
          this.toggleViewState(true);
          clearInterval(expandInterval);
        }, delayExpanding * 1000);
      }
      this.setState({ didAutoMaximize: true }); // we save a flag so that we only do this once
      this.removeScrollListener(); // remove the scroll listener, no longer needed
    }
  };
}

RollieContainer.propTypes = {
  appConfiguration: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default RollieContainer;
