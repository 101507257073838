import {
  getAutoInsertElement,
  getFirstMatchingRefElement,
  getRefElementsForFirstMatch,
  insertAfter,
  insertBefore,
  insertElementNextToRef,
} from './domHelper';

export default (themeConfig: any) => {
  const { placement, placementIndex } = themeConfig;
  const element = getVerifiedReferenceElement(placement);

  if (!element) {
    return undefined;
  }

  if (!placement.children || !placement.children.selectors || placement.children.selectors.length === 0) {
    return insertElementNextToRef(element, placementIndex && !isNaN(placementIndex) ? placementIndex < 0 : true);
  }

  return insertElementBasedOnChild(element, placement, placementIndex);
};

const getVerifiedReferenceElement = placement => {
  const { verify, selectors } = placement;
  const element = getFirstMatchingRefElement(selectors);
  if (!element) {
    return;
  }
  return verify ? verify(element) : element;
};

export const insertElementBasedOnChild = (element, placement, placementIndex) => {
  const selectorChildren = getRefElementsForFirstMatch(element, placement.children.selectors);
  const children = selectorChildren && selectorChildren.length > 0 ? selectorChildren : element.children;
  if (!children || children.length === 0) {
    return undefined;
  }

  const newRoot = getAutoInsertElement();

  if (placementIndex >= children.length) {
    insertAfter(newRoot, children[children.length - 1]);

    return {
      onsiteElement: newRoot,
      inline: true,
    };
  }

  const refNode = children[placementIndex];
  insertBefore(newRoot, refNode);
  return {
    onsiteElement: newRoot,
    inline: true,
  };
};
