import { DEFAULT_DESKTOP_PLACEMENT, DEFAULT_MOBILE_PLACEMENT, PlacementType } from '../constants';
import { PLACEMENT_OPTIONS } from '../enum';
import { findMatcher, matchRoute, mergeThemeConfig } from './matcher';
import { getPagePlacementMapping } from './pageDetection';
import { defaultPlacementThemes, placements, placementThemes } from './placementMapping';

export const NONE_PLACEMENT_TYPE = { type: PlacementType.NONE };
export const FIXED_PLACEMENT_TYPE = { type: PlacementType.FIXED };
export default (personalization: any, theme: string, platform: string, currPath: string) => {
  if (!personalization || !personalization.placements) {
    return NONE_PLACEMENT_TYPE;
  }

  const paths = personalization.placements.paths;
  if (!paths) {
    return getBuiltInThemeConfig(personalization, theme, platform, currPath);
  }

  const pagePlacementMapping = getPagePlacementMapping();
  if (isOnExcludedPage(pagePlacementMapping, platform)) {
    return NONE_PLACEMENT_TYPE;
  }

  if (!paths.placementType) {
    return FIXED_PLACEMENT_TYPE;
  }

  return getPlacementByUserSelection(pagePlacementMapping, platform, paths, currPath, theme);
};

const getPlacementByUserSelection = (pagePlacementMapping, platform, paths, currPath, theme) => {
  const validPathReturn = {
    type:
      theme === DEFAULT_DESKTOP_PLACEMENT || theme === DEFAULT_MOBILE_PLACEMENT
        ? PlacementType.FIXED
        : PlacementType.INLINE,
    placement: placements[platform].footer, // Only used for inline
    placementIndex: -1, // default insert new element as previous sibling to selected element
  };

  switch (paths.placementType) {
    case PLACEMENT_OPTIONS.INCLUDE_ALL_PAGES:
      return validPathReturn;
    case PLACEMENT_OPTIONS.INCLUDE_SELECT_PAGES:
      return checkIncludedPages(pagePlacementMapping, platform, paths) ? validPathReturn : NONE_PLACEMENT_TYPE;
    case PLACEMENT_OPTIONS.EXCLUDE_PATHS:
      if (paths.excludes && findMatcher(paths.excludes, currPath)) {
        return validPathReturn;
      }
  }
  return NONE_PLACEMENT_TYPE;
};

const checkIncludedPages = (pagePlacementMapping, platform, paths) => {
  const canInclude = paths.includes.some(pi => {
    const isNotOther = pi.value !== 'other';
    if (isNotOther) {
      return (
        pi.checked && // Only check page if that page type is checked
        pagePlacementMapping[platform][pi.value] !== undefined &&
        pagePlacementMapping[platform][pi.value]()
      );
    }

    /*             
      Other means it Should not evaluate to any other page on the platform in PageDetection.
      Included here as `other` is an option inside `paths.includes`
    */
    const canDetectPageMatch = Object.keys(pagePlacementMapping[platform]).some(
      key => pagePlacementMapping[platform][key]() !== undefined
    );

    // Other means that it does not match with existing pattern values
    return !canDetectPageMatch;
  });
  return canInclude;
};
/**
 * Uses built in pre-populated `placementThemes` which to figure out where to place the widget
 * Tries to reuse selectors on page and uses the ecommerce platform to identify which selector mapping to take into consideration
 * @param personalization
 * @param theme
 * @param platform
 * @param path
 */
const getBuiltInThemeConfig = (personalization: any, theme: string, platform: string, path: string) => {
  const platformConfig = placementThemes[platform] || defaultPlacementThemes;

  const platformThemeConfig: any[] = platformConfig[theme];

  if (!platformThemeConfig || platformThemeConfig.length < 1) {
    return undefined;
  }

  const mergedPlatformThemeConfig = mergeThemeConfig(platformThemeConfig, personalization.placements.overrides || []);
  const themeConfig = mergedPlatformThemeConfig.find(t => matchRoute(platform, path, t.path));
  return themeConfig;
};

// Check each excluded page and if it returns true that means we are currently on an excluded page
const isOnExcludedPage = (pagePlacementMapping, platform) => {
  const EXCLUDED_LIST = ['conversion', 'orderconfirmation'];
  return EXCLUDED_LIST.some(
    e => pagePlacementMapping[platform][e] !== undefined && pagePlacementMapping[platform][e]()
  );
};
