import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { default as cx } from 'classnames';

import { ATTRIBUTION_TRACKING } from 'utils/constants';
import { trackCurrentlyVisibleProducts } from 'utils';
import { track } from '../../../index';

import ProductCard from 'components/ForestRollie/ProductCard';

export class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = { trackedSwipe: false };
  }

  handleTouchMove = e => {
    if (!this.state.trackedSwipe) {
      track(ATTRIBUTION_TRACKING.ONSITE_CAROUSEL_SWIPE, {});
      this.setState({ trackedSwipe: true });
    }
  };

  handleTouchEnd = e => {
    // only track visible products if the user swiped (scrolled to view different products)
    if (this.state.trackedSwipe) {
      this.setState({ trackedSwipe: false });
      trackCurrentlyVisibleProducts(track, ATTRIBUTION_TRACKING.ONSITE_CAROUSEL_SWIPE);
    }
  };

  render() {
    const classNames = cx('adroll-rollie-forest-carousel', {
      'adroll-rollie-forest-carousel-hidden': !this.props.selected,
      'adroll-rollie-forest-carousel-disabled': this.props.disabled,
    });

    const products =
      this.props.tab.collectionId || this.props.tab.selectedProducts
        ? this.props.customTabsProducts[this.props.tab.id]
        : this.props.tab.products;

    return (
      <div
        className={classNames}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        style={this.props.appConfiguration.personalization.carousel.styles}
      >
        {products.map((product, index) => (
          <ProductCard
            key={product.id}
            product={product}
            disabled={this.props.disabled}
            previewModeType={this.props.previewModeType}
          />
        ))}
      </div>
    );
  }
}

Carousel.propTypes = {
  tab: PropTypes.object.isRequired,
  customTabsProducts: PropTypes.object,
  appConfiguration: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  previewModeType: PropTypes.string,
};

export default Carousel;
