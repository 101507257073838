import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MinimizeButton extends Component {
  handleClick = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="adroll-rollie-web-minimize-button">
        <span className="adroll-rollie-minimize-button-label-left" />
        <button onClick={this.handleClick} style={this.props.appConfiguration.personalization.minimizeButton.styles} />
        <span className="adroll-rollie-minimize-button-label-right" />
      </div>
    );
  }
}

MinimizeButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
};

export default MinimizeButton;
