import { default as cx } from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getAddToCartHandler } from 'utils';
import { ATTRIBUTION_TRACKING } from 'utils/constants';
import { truncateText } from 'utils/index';
import { canAddToCart } from 'utils/isDnd';
import { getWebProductCardDimensions } from 'utils/webContentScaling';
import { dispatchResizeEvent } from 'utils/window';
import { track } from '../../../index';

class ProductCard extends Component {
  render() {
    const { product, appConfiguration, inline } = this.props;
    const price = product.sale_price || product.price;

    const { variant_ids, idhash, source } = product;
    const canAddToCartVal = canAddToCart(variant_ids, appConfiguration);
    const appCustomizations = appConfiguration.personalization;
    const noBuilderCustomizations = appConfiguration.noBuilderCustomizations;
    const addToCartHandler = getAddToCartHandler(appConfiguration.ecommerce_platform);
    const dynamicStyle = {
      width: canAddToCartVal ? '7.5em' : '5.625em',
      minWidth: canAddToCartVal ? '7.5em' : '5.625em',
      maxWidth: canAddToCartVal ? '7.5em' : '5.625em',
    };
    const buttonStyle = {
      ...appCustomizations.product.actionButton.styles,
      ...dynamicStyle,
    };
    const cartData = this.props.cartData;
    const showProductPrice = noBuilderCustomizations.features.showProductPrice;

    const productCardClasses = cx('adroll-rollie-web-product-card', {
      'adroll-rollie-web-product-card-active': this.props.isActive,
    });

    const dimensions = getWebProductCardDimensions(appConfiguration, inline);

    const productStyles = {
      ...appCustomizations.product.styles,
      ...dimensions,
    };

    const imgStyles = { maxHeight: productStyles.height, maxWidth: productStyles.width, width: 'auto' };
    return (
      <div
        productid={product.idhash}
        source={product.source}
        className={productCardClasses}
        style={productStyles}
        onClick={e => {
          track(ATTRIBUTION_TRACKING.ONSITE_PRODUCT_CLICK, {
            productId: idhash,
            variantId: variant_ids,
            source,
          });
        }}
      >
        <a href={this.props.previewModeType ? null : product.url}>
          <img
            style={imgStyles}
            src={product['i/image']}
            onLoad={dispatchResizeEvent}
            className="adroll-rollie-product-image"
            alt={product.title}
          />
          <div className="adroll-rollie-product-card-overlay">
            <div className="adroll-rollie-product-card-info">
              <div className="adroll-rollie-product-card-title" style={appCustomizations.product.title.styles}>
                {truncateText(product.title, 23)}
              </div>
              {showProductPrice ? (
                <div className="adroll-rollie-product-card-price" style={appCustomizations.product.price.styles}>
                  {canAddToCartVal ? price : `${price}+`}
                </div>
              ) : null}
              <div>
                {canAddToCartVal ? (
                  <button
                    className="adroll-rollie-product-card-button"
                    onClick={e => addToCartHandler(e, track, cartData, product)}
                    style={buttonStyle}
                  >
                    Add to Cart
                  </button>
                ) : (
                  <button style={buttonStyle} className="adroll-rollie-product-card-button">
                    View
                  </button>
                )}
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  dimensions: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  appConfiguration: PropTypes.object.isRequired,
  cartData: PropTypes.object,
  previewModeType: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default ProductCard;
