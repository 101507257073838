import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TabButton from 'components/ForestRollie/TabButton';

class TabPickerItem extends Component {
  handleClick = e => {
    this.props.setSelectedTab(this.props.tab);
    e.preventDefault();
  };

  render() {
    return (
      <TabButton
        className="adroll-rollie-forest-tab-switch-buttons"
        appConfiguration={this.props.appConfiguration}
        onClick={this.handleClick}
        tab={this.props.tab}
      />
    );
  }
}

TabPickerItem.propTypes = {
  tab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
};

export default TabPickerItem;
