interface PatternMatcher {
  pattern: string;
  matcher: string;
}
export enum MatcherTypes {
  equals = '=',
  contains = 'contains', // '\\b' + subPattern + '\\b'
  startsWith = 'startsWith', // '^' + subPattern
  all = '*',
}
/**
 * Determines if current window route matches any of the routes with pattens
 * @param patternMatchers Pattern matchers to test against for current route
 * @param path Current window route
 */

export const findMatcher = (patternMatchers: PatternMatcher[], path: string) => {
  return patternMatchers.some((p: PatternMatcher) => {
    if (p.matcher === MatcherTypes.all) {
      return true;
    }
    if (p.matcher === MatcherTypes.equals) {
      return path && p.pattern.toLowerCase() === path.toLowerCase();
    }
    const searchPattern = new RegExp(p.pattern, 'i'); // case insensitive regex match
    return searchPattern.test(path);
  });
};

export const matchRoute = (platform: string, path: string, pattern: string) => {
  return path.includes(pattern) || pattern === '*';
};

export const mergeThemeConfig = (platformThemeConfig: any[], overrides: any[]) => {
  if (!overrides || overrides.length < 1) {
    return platformThemeConfig;
  }

  const map = overrides.reduce((obj, item) => {
    obj[item['path']] = item;
    return obj;
  }, {});
  return platformThemeConfig.map(x => (map[x.path] ? map[x.path] : x));
};
