import { addToCartHandler as addToCartHandlerBigCommerce, getCart as getCartBigCommerce } from './api/bigcommerce';
import { getCart as getCartMagento } from './api/magento';
import { getCart as getCartMagento2 } from './api/magento2';
import { addToCartHandler as addToCartHandlerShopify, getCart as getCartShopify } from './api/shopify';
import {
  ATTRIBUTION_TRACKING,
  PLATFORM_BIGCOMMERCE,
  PLATFORM_MAGENTO,
  PLATFORM_MAGENTO2,
  PLATFORM_SHOPIFY,
} from './constants';
import later from './later';

export function truncateText(text, length) {
  if (text.length <= length) {
    return text;
  }
  return `${text.substring(length, 0)}...`;
}

export function addMarginBottomToBody() {
  document.body.style.marginBottom = '185px';
}

export function removeMarginBottomFromBody() {
  document.body.style.marginBottom = '0';
}

export const safeJsonParse = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getEnvironment = () => {
  return process.env.NODE_ENV === 'development' ? 'docker_development' : process.env.REACT_APP_RUNTIME_ENV;
};

export const defaultArray = (destinationArray, sourceArray, options) => {
  /*
    Function used in deepmerge.
    By default, `deepmerge` concatenates arrays, meaning that the
    arrays will have duplicate data on every load.
    This function checks if the array in the `onsiteUser` object has no data,
    and if so, replaces that empty array with the defaults.
  */
  if (sourceArray.length === 0) {
    return destinationArray;
  }
  return sourceArray;
};

export const getCartFunction = platform => {
  /*
    Function that returns the correct getCart function to be used
    based on the information we have about the website's platform.
  */
  switch (platform) {
    case PLATFORM_SHOPIFY:
      return getCartShopify;
    case PLATFORM_BIGCOMMERCE:
      return getCartBigCommerce;
    case PLATFORM_MAGENTO:
      return getCartMagento;
    case PLATFORM_MAGENTO2:
      return getCartMagento2;
    default:
      // Any other case, including null, let's not use any cart api function.
      return null;
  }
};

export const getAddToCartHandler = platform => {
  /*
    Function that returns the correct addToCart function to be used
    based on the information we have about the website's platform.
  */
  switch (platform) {
    case PLATFORM_SHOPIFY:
      return addToCartHandlerShopify;
    case PLATFORM_BIGCOMMERCE:
      return addToCartHandlerBigCommerce;
    case PLATFORM_MAGENTO:
      return null; // No Magento support for now.
    case PLATFORM_MAGENTO2:
      return null; // No Magento2 support for now.
    default:
      // Any other case, including null, should be threat as null, aka do nothing.
      return null;
  }
};

export const isElementVisible = element => {
  return element.offsetWidth > 0 && element.offsetHeight > 0;
};

export const getCurrentlyVisibleProducts = () => {
  const productCards = Array.from(
    document.querySelectorAll(
      '.adroll-rollie-web-product-card, .adroll-rollie-mobile-product-card, .adroll-rollie-forest-product-card'
    )
  );
  return productCards
    .filter(productCard => isElementVisible(productCard) && isElementInViewPort(productCard))
    .map(productCard => ({
      source: productCard.getAttribute('source'),
      id: productCard.getAttribute('productid'),
    }));
};

export const isElementInViewPort = element => {
  if (!element || !element.getBoundingClientRect) {
    return undefined;
  }

  const threshold = 0.6;
  // this controls how much of the product's bounding rect
  // must be inside the view port to be considered "visible".
  // assuming a threshold of 0.6, the rules for checking the element are:
  //     its bottom border must be at least 60% of its height from the window top
  //     AND
  //     its top border must be at least 60% of its height from the window bottom
  //     AND
  //     its left border must be at least 60% of its width from the window right
  //     AND
  //     its right border must be at least 60% of its width from the window left

  const bounding = element.getBoundingClientRect();
  const verticalLeeway = element.offsetHeight * threshold;
  const horizontalLeeway = element.offsetWidth * threshold;
  const viewPortTop = 0;
  const viewPortLeft = 0;
  const viewPortRight = window.innerWidth || document.documentElement.clientWidth;
  const viewPortBottom = window.innerHeight || document.documentElement.clientHeight;

  return (
    bounding.bottom >= viewPortTop + verticalLeeway &&
    bounding.top <= viewPortBottom - verticalLeeway &&
    bounding.right >= viewPortLeft + horizontalLeeway &&
    bounding.left <= viewPortRight - horizontalLeeway
  );
};

export const trackCurrentlyVisibleProducts = (track, userAction, timeout = 350) => {
  // Track all currently visible product card ids after a delay to make
  // sure any animations and loading are already finished.
  const productsBeforeDelay = getCurrentlyVisibleProducts();
  return later(timeout, () => {
    const productIdsAfterDelay = getCurrentlyVisibleProducts();

    track(ATTRIBUTION_TRACKING.ONSITE_PRODUCTS_VIEWED, {
      userAction,
      productsBeforeDelay,
      products: productIdsAfterDelay,
    });
  });
};

export const getDeepObject = (obj, path, create = false) => {
  // Given an object and an array of attributes, get the deep attribute,
  // optionally create it if it doesn't exist.
  if (path.length) {
    path.forEach(key => {
      if (create && !(key in obj)) {
        obj[key] = {};
      }
      obj = obj[key];
    });
  }
  return obj;
};

export const getCurrentProductGroup = appConfiguration => {
  if (window.adroll_product_group) {
    return window.adroll_product_group;
  }
  const productGroupRules = appConfiguration.noBuilderCustomizations.productGroup.urlRules;
  for (let index = 0; index < productGroupRules.length; index++) {
    const regexResult = new RegExp(productGroupRules[index].regex_rule).exec(window.location.href);
    if (regexResult) {
      return productGroupRules[index].product_group;
    }
  }

  return appConfiguration.noBuilderCustomizations.productGroup.default || null;
};
