import promise from 'es6-promise';
import { getProductVariantsFromProducts } from '../mapper';
import { makeRequest } from './api';
import { safeJsonParse } from '../index';
import { ATTRIBUTION_TRACKING } from '../constants';

const PAGE_SIZE = 250;

export const addToCart = (quantity, variantId) => {
  return makeRequest('POST', '/cart/add.js', {
    data: {
      dataType: 'json',
      quantity: parseInt(quantity, 10),
      id: variantId, // Using the ajax api returns the variantId
      properties: {
        source: 'adroll-onsite',
      },
    },
  }).then(resp => (resp ? JSON.parse(resp) : undefined));
};

export const getCartFromApi = () => {
  return makeRequest('GET', '/cart.js').then(resp => {
    if (typeof resp === 'undefined') {
      return promise.Promise.reject(
        new Error('Error getting shopify cart contents.')
      );
    }
    return resp ? safeJsonParse(resp) : undefined;
  });
};

export const getCart = () => {
  return getCartFromApi().then(apiResponse => {
    return getCartData(apiResponse);
  });
};

export const getCartData = apiResponse => {
  const cartData = [];
  if (apiResponse && apiResponse.items) {
    apiResponse.items.forEach(item => {
      cartData.push(item.product_id);
    });
  }

  return {
    items: cartData,
    itemsCount: getCartCount(apiResponse),
    // Since shopify is the default platform we use to try to get the cart in case
    // we don't know the ecommerce platform, if the api response is not valid,
    // we can't guarantee the correct cart url, so let's return null.
    cartUrl: apiResponse ? '/cart' : null,
  };
};

export const getCartCount = apiResponse => {
  if (apiResponse && apiResponse.item_count) {
    return apiResponse.item_count;
  }
  return 0;
};

export const addToCartHandler = (e, track, cartData, product) => {
  if (e) {
    e.stopPropagation(); // Needed to stop bubbling up (and triggering a product view track event)
    e.preventDefault(); // This event might be within a <a> tag
  }

  const variantId = product.variant_ids;

  track(ATTRIBUTION_TRACKING.ONSITE_ADD_TO_CART, {
    productId: product.id,
    variantId,
    source: product.source,
    quantity: 1,
  });
  return addToCart(1, variantId);
};
