import React from 'react';

export default ({ inline }) => (
  <div className={`adroll-rollie-powered-by-nextroll ${inline ? 'adroll-powered-inline' : 'adroll-powered-overlay'}`}>
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 943 101">
      <g>
        <path
          className="st0"
          d="M505.1,50.3c0-27.5-21.6-49.8-49.8-49.8c-18.7,0-35,10.3-43.5,25.5l24.3,24.3l0,0c0-10.6,9-19.2,19.2-19.2
        c10.9,0,19.2,8.6,19.2,19.2c0,10.6-8.4,19.2-19.2,19.2l-0.1,0l0,0h-41.3c-10.3,0-18.6,8.3-18.6,18.6v12h60
        C483.3,100.2,505.1,77.9,505.1,50.3"
        />
        <path
          className="st1"
          d="M731.9,43.6h23.3c9,0,13.7-4.3,13.7-12.2c0-8.1-4.7-12.4-13.7-12.4h-23.3V43.6z M745.3,62.9h-13.4v36.3H711
        V10.8c0-6.2,5-11.2,11.2-11.2h33.4c21.5,0,34.7,12.9,34.7,31.6c0,14.9-8.4,26.3-23,30.3l26,37.7h-25L745.3,62.9z M943,96.6
        c-4.1,2.6-8.9,4.5-14.1,4.5c-14.4,0-21-9.8-21-23.8V4.9l19.7-4.7v77.4c0,3.9,1.9,6.3,5.4,6.3c1.1,0,2.2-0.3,3.3-0.7L943,96.6z
        M908.6,96.6c-4.1,2.6-8.9,4.5-14.1,4.5c-14.4,0-21-9.8-21-23.8V13l19.7-4.9v69.3c0,3.9,1.9,6.3,5.4,6.3c1.1,0,2.2-0.3,3.3-0.7
        L908.6,96.6z M846.8,63.2c0-11.5-7.4-19.5-18.5-19.5c-11.1,0-18.5,7.8-18.5,19.5c0,11.7,7.4,19.5,18.5,19.5
        C839.3,82.7,846.8,74.9,846.8,63.2 M789.6,63.2c0-22.2,16.3-37.7,38.7-37.7c22.4,0,38.8,15.4,38.8,37.7c0,22.3-16.4,37.9-38.8,37.9
        C805.8,101.1,789.6,85.6,789.6,63.2 M562.5,59L576.7,21L590.9,59H562.5z M561.8,6.7l-36.4,92.5h22.1l7.8-20.8h42.9l7.8,20.8h22.1
        L588.8-0.4h-16.6C567.6-0.4,563.5,2.4,561.8,6.7 M663.5,82.7c-11,0-18.5-7.8-18.5-19.5c0-11.7,7.6-19.5,18.5-19.5
        c11,0,18.5,7.8,18.5,19.5C682,75.2,674.3,82.7,663.5,82.7 M680.7,34.5c-4.9-5.7-12.3-9-21.3-9c-19.3,0-34.7,15.4-34.7,37.6
        c0,22.3,15.4,38,34.1,38c10,0,17.8-4.4,22.8-11.7l0.9,9.8h17.7V-0.4H692c-6.2,0-11.2,5-11.2,11.2V34.5z"
        />
        <g>
          <path
            d="M25,32.9c2.6,2.3,3.9,5.5,3.9,9.4c0,4-1.3,7.1-3.9,9.4c-2.6,2.3-6.1,3.5-10.4,3.5H4.7V72h-5.2V29.4h15
          C18.9,29.4,22.3,30.6,25,32.9z M21.1,48.4c1.6-1.4,2.4-3.4,2.4-6.1c0-2.7-0.8-4.7-2.4-6.1c-1.6-1.4-4-2-7.3-2H4.7v16.3h9.1
          C17.1,50.5,19.6,49.8,21.1,48.4z"
          />
          <path
            d="M62.4,43.5c2.8,3,4.2,7.1,4.2,12.3c0,5.2-1.4,9.4-4.2,12.3c-2.8,3-6.6,4.4-11.4,4.4c-4.9,0-8.7-1.5-11.4-4.4
          c-2.8-3-4.2-7.1-4.2-12.3c0-5.2,1.4-9.4,4.2-12.3c2.8-3,6.6-4.5,11.4-4.5C55.8,39.1,59.6,40.5,62.4,43.5z M43.3,46.8
          c-1.8,2.2-2.8,5.2-2.8,9c0,3.8,0.9,6.8,2.8,9c1.8,2.2,4.4,3.3,7.7,3.3c3.3,0,5.8-1.1,7.7-3.3c1.8-2.2,2.8-5.2,2.8-9
          c0-3.8-0.9-6.8-2.8-9c-1.8-2.2-4.4-3.3-7.7-3.3C47.7,43.5,45.1,44.6,43.3,46.8z"
          />
          <path d="M122.4,39.7L111.1,72H106l-9.1-25.7L88.3,72h-5.2L71.9,39.7h5.7L85.8,66l8.8-26.2h5.2l8.8,26.2l8.1-26.2H122.4z" />
          <path
            d="M157.1,57.8h-24.3c0.3,3.3,1.4,5.8,3.3,7.6c1.9,1.8,4.3,2.7,7.3,2.7c2.4,0,4.3-0.4,5.9-1.2c1.6-0.8,2.6-2,3.2-3.5l4.3,2.3
          c-1.3,2.3-3,4-5.2,5.2c-2.2,1.2-4.9,1.8-8.3,1.8c-4.9,0-8.7-1.5-11.4-4.4c-2.8-3-4.2-7.1-4.2-12.3c0-5.2,1.3-9.4,4-12.3
          c2.6-3,6.4-4.5,11.3-4.5c3,0,5.6,0.7,7.8,2c2.2,1.3,3.8,3.1,5,5.4c1.1,2.3,1.7,4.8,1.7,7.7C157.4,55.4,157.3,56.6,157.1,57.8z
          M136.1,46.1c-1.7,1.7-2.8,4-3.2,7.1h19.5c-0.3-3-1.3-5.4-3-7.1c-1.6-1.7-3.8-2.5-6.5-2.5C140.1,43.6,137.8,44.4,136.1,46.1z"
          />
          <path
            d="M185.9,39.7l-1,5c-1-0.6-2.4-0.8-4.1-0.8c-1.4,0-2.7,0.4-4,1.2c-1.3,0.8-2.4,2-3.2,3.6s-1.3,3.6-1.3,5.8V72h-5.2V39.7h4.2
          l0.7,5.3c1.8-4,5.2-5.9,10.3-5.9C183.9,39.1,185.1,39.3,185.9,39.7z"
          />
          <path
            d="M219.9,57.8h-24.3c0.3,3.3,1.4,5.8,3.3,7.6c1.9,1.8,4.3,2.7,7.3,2.7c2.4,0,4.3-0.4,5.9-1.2c1.6-0.8,2.6-2,3.2-3.5l4.3,2.3
          c-1.3,2.3-3,4-5.2,5.2c-2.2,1.2-4.9,1.8-8.3,1.8c-4.9,0-8.7-1.5-11.4-4.4c-2.8-3-4.2-7.1-4.2-12.3c0-5.2,1.3-9.4,4-12.3
          c2.6-3,6.4-4.5,11.3-4.5c3,0,5.6,0.7,7.8,2c2.2,1.3,3.8,3.1,5,5.4c1.1,2.3,1.7,4.8,1.7,7.7C220.1,55.4,220,56.6,219.9,57.8z
          M198.8,46.1c-1.7,1.7-2.8,4-3.2,7.1h19.5c-0.3-3-1.3-5.4-3-7.1c-1.6-1.7-3.8-2.5-6.5-2.5C202.8,43.6,200.6,44.4,198.8,46.1z"
          />
          <path
            d="M258.6,24.9V72H254l-0.3-5.6c-0.9,1.9-2.4,3.4-4.4,4.5c-2,1.1-4.2,1.7-6.7,1.7c-4.5,0-8.1-1.5-10.7-4.5
          c-2.6-3-4-7.1-4-12.3c0-5.2,1.3-9.3,4-12.3c2.6-3,6.2-4.5,10.7-4.5c2.4,0,4.5,0.5,6.4,1.5s3.3,2.4,4.4,4.1V24.9H258.6z
          M250.7,64.9c1.8-2.1,2.7-5,2.7-8.6v-0.8c0-3.6-0.9-6.5-2.7-8.6c-1.8-2.1-4.3-3.2-7.4-3.2c-3.1,0-5.6,1.1-7.4,3.3
          c-1.8,2.2-2.7,5.2-2.7,8.9c0,3.7,0.9,6.7,2.7,8.9c1.8,2.2,4.3,3.3,7.4,3.3C246.4,68.1,248.8,67,250.7,64.9z"
          />
          <path
            d="M319.2,43.5c2.6,3,4,7.1,4,12.3c0,5.2-1.3,9.3-4,12.3c-2.6,3-6.2,4.5-10.7,4.5c-2.5,0-4.7-0.6-6.7-1.7
          c-2-1.1-3.4-2.7-4.4-4.5l-0.3,5.6h-4.5V24.9h5.2v19.9c1-1.7,2.5-3.1,4.4-4.1c1.9-1,4-1.5,6.4-1.5
          C312.9,39.1,316.5,40.6,319.2,43.5z M315.3,64.8c1.8-2.2,2.7-5.2,2.7-8.9c0-3.7-0.9-6.7-2.7-8.9c-1.8-2.2-4.3-3.3-7.4-3.3
          c-3.1,0-5.6,1.1-7.4,3.2c-1.8,2.2-2.7,5-2.7,8.6v0.8c0,3.6,0.9,6.5,2.7,8.6c1.8,2.2,4.3,3.2,7.4,3.2C311,68.1,313.4,67,315.3,64.8
          z"
          />
          <path
            d="M344.5,77.4c-1.2,3.1-2.6,5.3-4.2,6.5s-3.5,1.9-6,1.9c-1.6,0-3-0.2-4.1-0.6s-2.1-1.1-3-2.1l1.8-4.4c1.2,1.7,3,2.6,5.2,2.6
          c1.2,0,2.4-0.4,3.3-1.1c1-0.8,1.8-2.1,2.5-4.1l1.7-4.2l-13.5-32.2h5.9l8.1,21l2,5.7l2.3-6.4l7.5-20.4h5.5L344.5,77.4z"
          />
        </g>
      </g>
    </svg>
  </div>
);

// export default poweredByAdroll;
