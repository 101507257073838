export const addEmbedIfPageHasId = (platform: string, config: any = {}, appConfiguration: any) => {
  const key: string = getInlineIdByPlatform(platform);

  const existingRoot = document.getElementById(key);

  const condition1 =
    config && config.deviceType === 'desktop' && appConfiguration.personalization.placements.desktop === 'abovefooter';
  const condition2 =
    config && config.deviceType === 'mobile' && appConfiguration.personalization.placements.mobile === 'abovefooter';
  const canEmbed = condition1 || condition2;

  if (existingRoot && canEmbed) {
    existingRoot.classList.add('adroll-onsite-root');
    existingRoot.classList.add('adroll-inline');
    return {
      onsiteElement: existingRoot,
      inline: true,
    };
  }
};

export const insertElementNextToRef = (referenceElem: any, insertBeforeElem: boolean) => {
  if (referenceElem && referenceElem.parentElement) {
    const newRoot = getAutoInsertElement();
    if (insertBeforeElem) {
      insertBefore(newRoot, referenceElem);
    } else {
      insertAfter(newRoot, referenceElem);
    }
    return {
      onsiteElement: newRoot,
      inline: true,
      referenceNode: referenceElem,
      insertBeforeElem,
    };
  }
  return undefined;
};

export const getAutoInsertElement = () => {
  const newRoot = document.createElement('div');
  newRoot.setAttribute('id', 'adroll-onsite-root-auto-insert');
  newRoot.classList.add('adroll-onsite-root');
  return newRoot;
};

export const insertAfter = (newNode, referenceNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};

export const insertBefore = (newNode, referenceNode) => {
  referenceNode.parentElement.insertBefore(newNode, referenceNode);
};

export const getInlineIdByPlatform = (platform: string) => {
  return 'adroll-onsite-root';
};

export const getFirstMatchingRefElement = (querySelArr: string[]) => {
  for (let i = 0; i < querySelArr.length; i++) {
    const query = querySelArr[i];
    const elem = document.querySelector(query);
    if (elem) {
      return elem;
    }
  }
};

export const getRefElementsForFirstMatch = (refElement: Element, querySelArr: string[]) => {
  for (let i = 0; i < querySelArr.length; i++) {
    const query = querySelArr[i];
    const elements = refElement.querySelectorAll(query);
    if (elements && elements.length > 0) {
      return elements;
    }
  }
};

export const removeAllChildren = (myNode: Element) => {
  while (myNode.firstChild) {
    myNode.removeChild(myNode.firstChild);
  }
};
