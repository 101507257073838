import { PREVIEW_MODE_TYPE_WIDGET_ONLY } from '../constants';
import { logMessage } from '../logger';
import addFixedFooter from './addFixedFooter';
import autoInsertOnPage from './autoInsertOnPage';
import { addEmbedIfPageHasId } from './domHelper';

/***
 * Precedence
 * a) Is preview mode (Showing on builder)?: Same as fixed footer.
 * b) If we do not have the eCommerce platform, then use default widget (Fixed footer)
 * c) Explicitly defined by customer by placing the id (advanced option)
 * d) Use implicit rules (for e.g. Platform themes for placing before footer)
 */

export default (
  platform: string,
  path: string,
  appConfiguration: any,
  previewModeType: string = undefined,
  config: any = {}
) => {
  if (previewModeType === PREVIEW_MODE_TYPE_WIDGET_ONLY) {
    logMessage(`Placing a fixed footer as we are in Preview mode`, 'PlaceRoot');
    return addFixedFooter();
  }

  if (!platform) {
    logMessage(`Placing a default fixed footer as no E-Comm platform found`, 'PlaceRoot');
    return addFixedFooter(); /* Default scenario as ecommerce_platform can be empty */
  }

  const canEmbedByIdResult = addEmbedIfPageHasId(platform, config, appConfiguration);
  if (canEmbedByIdResult) {
    logMessage('Placing inline for explicit replacement', 'PlaceRoot');
    return canEmbedByIdResult;
  }

  const canAutoInsertOnPage = autoInsertOnPage(platform, path, appConfiguration, config);
  if (canAutoInsertOnPage) {
    logMessage('Auto inserting the widget', 'PlaceRoot');
    return canAutoInsertOnPage;
  }

  logMessage('Placements was unable to figure out whether to place the widget.  Hence doing nothing!', 'PlaceRoot');
};
