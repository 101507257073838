export const readFromSession = key => {
  if (!sessionStorage) {
    return {};
  }
  const val = sessionStorage.getItem(key);
  return val ? JSON.parse(val) : {};
};

export const writeToSession = (key, val) => {
  if (!sessionStorage) {
    return;
  }
  sessionStorage.setItem(key, JSON.stringify(val ? val : {}));
};

let _storage = {};

export const MemoryStorage = {
  get: key => {
    return _storage[key] || null;
  },

  set: (key, value) => {
    _storage[key] = value;
  },
};
