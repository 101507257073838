import { makeRequest } from './api';
import {
  CACHE_ROOT_DOMAIN,
  NO_BUILDER_CUSTOMIZATIONS_FILE_NAME,
} from '../constants';
import { getEnvironment } from '../index';

export const getS3Customizations = (
  advertisableEid: string,
  is_test: boolean,
  shortFileName: string
) => {
  const environment = getEnvironment();
  const fileName =
    is_test && shortFileName !== NO_BUILDER_CUSTOMIZATIONS_FILE_NAME
      ? `${shortFileName}-test.json`
      : `${shortFileName}.json`;
  const url = `${CACHE_ROOT_DOMAIN}/onsite_personalization/${environment}/advertiser-personalizations/${advertisableEid}/${fileName}`;
  return makeRequest('GET', url, null, false).then(response => {
    return JSON.parse(response);
  });
};

export const getS3PreviewConfig = (advertisableEid: string) => {
  const environment = getEnvironment();
  const url = `${CACHE_ROOT_DOMAIN}/onsite_personalization/${environment}/advertiser-personalizations/${advertisableEid}/config.preview.json`;
  return makeRequest('GET', url, null, false).then(response => {
    return JSON.parse(response);
  });
}
