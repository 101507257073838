import promise from 'es6-promise';
import { ATTRIBUTION_TRACKING, CACHE_ROOT_DOMAIN } from '../constants';
import { getFirstPartyCookie } from '../cookie';
import { makeRequest } from './api';
import md5 from 'md5';
import { getCurrentProductGroup } from '../index';
import { dynoUrl } from '../dyno';

const getProductGroupHash = productGroup => {
  return md5(productGroup).substring(0, 8);
};

const getProductStreamsCategorized = (
  advertisable_eid: string,
  appConfiguration: object,
  min_products: number = 30
) => {
  const productGroup = getCurrentProductGroup(appConfiguration);
  let init_vars;
  if (productGroup) {
    init_vars = encodeURIComponent(
      JSON.stringify({ product_group: getProductGroupHash(productGroup) })
    );
  }
  let url = dynoUrl(
    `/trecs?advertisable_eid=${advertisable_eid}&categorize_recs=true&min_products=${min_products}&client=onsite`
  );
  if (init_vars) {
    url = `${url}&initvars=${init_vars}`;
  }
  return makeRequest('GET', url).then(resp =>
    resp ? JSON.parse(resp) : undefined
  );
};

const getCustomTabsProducts = (
  advertisableEid,
  productStreamEid,
  appConfiguration,
  track
) => {
  const promiseList = [];
  appConfiguration.personalization.header.customTabs.map(tab => {
    const init_vars = encodeURIComponent(
      JSON.stringify({ product_group: getProductGroupHash(tab.product_group) })
    );
    const url = dynoUrl(
      `/trecs?advertisable_eid=${advertisableEid}&categorize_recs=true&min_products=30&client=onsite&initvars=${init_vars}`
    );

    promiseList.push(
      makeRequest('GET', url, null, false)
        .then(resp =>
          resp
            ? {
                id: tab.id,
                products: JSON.parse(resp).topProducts || [],
              }
            : undefined
        )
        .catch(
          // If the recs API call fails we just return an empty product list
          () => {
            track(
              ATTRIBUTION_TRACKING.ONSITE_CUSTOM_TABS_PRODUCTS_LOAD_FAILED,
              { customTab: tab.id }
            );
            return [];
          }
        )
    );
  });

  return promise.Promise.all(promiseList).then(promiseResultsList => {
    const customTabsProducts = {};
    promiseResultsList.map(current => {
      customTabsProducts[current.id] = current.products;
    });
    return customTabsProducts;
  });
};

export const getProductStream = (
  advertisableEid: string,
  appConfiguration: object,
  productStreamEid: string,
  track: any = () => {}
) => {
  let productStreamPromise = getProductStreamsCategorized(
    advertisableEid,
    appConfiguration
  ).catch(
    // If the trecs api call fails, we return empty arrays
    () => {
      track(ATTRIBUTION_TRACKING.ONSITE_RECOMMENDED_PRODUCTS_LOAD_FAILED, {});
      return {
        topProducts: [],
        recommendedProducts: [],
        identityProducts: [],
        meta: {},
      };
    }
  );

  let customTabsProductsPromise = getCustomTabsProducts(
    advertisableEid,
    productStreamEid,
    appConfiguration,
    track
  ).catch(
    // If the trecs api call fails, we return an empty object
    () => {
      track(ATTRIBUTION_TRACKING.ONSITE_CUSTOM_TABS_PRODUCTS_LOAD_FAILED, {});
      return {};
    }
  );

  return promise.Promise.all([
    productStreamPromise,
    customTabsProductsPromise,
  ]).then(([productStream, customTabsProducts]) => {
    // Replace the trecs api response's topProducts array with the recs api response, if we have products in the recs list.
    let response = {
      identityProducts: productStream.identityProducts || [],
      recommendedProducts: productStream.recommendedProducts || [],
      topProducts: productStream.topProducts || [],
      meta: productStream.meta,
      customTabsProducts: customTabsProducts,
    };
    return response;
  });
};
