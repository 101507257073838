export default () => {
  const newRoot = document.createElement('div');
  newRoot.setAttribute('id', 'adroll-onsite-root-not-inline');
  newRoot.classList.add('adroll-onsite-root');
  document.body.appendChild(newRoot);
  return {
    onsiteElement: newRoot,
    inline: false,
  };
};
