import {
  findAllCollectionElement,
  findCartElement,
  findCollectionElement,
  findElementBySelector,
  findInlineFooterElement,
  findProductElement,
} from './placement';

const keyToFuncMap = {
  findInlineFooterElement,
  findProductElement,
  findCartElement,
  findCollectionElement,
  findAllCollectionElement,
};

export const handleGlobal = global => {
  const { resolver, placementIndex } = global;

  return handlePlacement(resolver, placementIndex);
};

const handlePlacement = (resolver, placementIndex) => {
  const val = keyToFuncMap[resolver] ? keyToFuncMap[resolver]() : findElementBySelector(resolver);
  if (keyToFuncMap[resolver]) {
    return {
      placementIndex,
      ...val,
    };
  }
};

export const handleIncluded = (pageKey, includePages) => {
  const myConfigPageVals = includePages.filter(x => x.name === pageKey);
  if (!myConfigPageVals || myConfigPageVals.length !== 1) {
    return;
  }
  const { resolver, placementIndex } = myConfigPageVals[0];
  return handlePlacement(resolver, placementIndex);
};

export const isCurrPageExcluded = (excludePages, currPageKey) =>
  excludePages.length > 0 && excludePages.some(e => e === currPageKey);
