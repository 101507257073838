import { PlacementType } from '../../constants';
import { placements } from '../../placements/placementMapping';
import addInline from '../addInline';
import { insertElementNextToRef } from '../domHelper';
import rules from './rules';

export default (appConfiguration: any, deviceType: string) => {
  const shopifyV2Result = rules(appConfiguration, deviceType);
  return shopifyV2Result ? handleShopifyV2Result(shopifyV2Result) : undefined;
};

const handleShopifyV2Result = shopifyV2Result => {
  const { type, placementIndex, element } = shopifyV2Result;
  const defaultHandler = () => {
    const themeConfig = {
      placementIndex,
      placement: placements.shopify.footer,
    };
    return addInline(themeConfig);
  };
  switch (type) {
    case PlacementType.NONE:
      return type;
    case PlacementType.INLINE:
      return insertElementNextToRef(element, placementIndex && !isNaN(placementIndex) ? placementIndex < 0 : true);
  }
  return defaultHandler();
};
