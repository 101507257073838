import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getAddToCartHandler } from 'utils';
import { track } from '../../../index';

class AddToCartButton extends Component {
  handleClick = e => {
    const { product, appConfiguration, cartData } = this.props;
    const addToCartHandler = getAddToCartHandler(appConfiguration.ecommerce_platform);
    addToCartHandler(e, track, cartData, product);
  };

  render() {
    return (
      <button
        className="adroll-rollie-mobile-add-to-cart-button"
        onClick={this.handleClick}
        style={this.props.appConfiguration.personalization.mobile.product.actionButton.styles}
      >
        Add to Cart
      </button>
    );
  }
}

AddToCartButton.propTypes = {
  product: PropTypes.object.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  cartData: PropTypes.object,
};

export default AddToCartButton;
