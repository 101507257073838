import * as dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserInfo } from './common/interfaces';
import Dimensions from './Dimensions';
import { getS3PreviewConfig } from './utils/api/s3';
import { LOCAL_STORAGE_PRODUCT_STREAM_KEY, MEDIA_QUERY_THEMES } from './utils/constants';
import placeRoot from './utils/placements';
import { getPreviewModeType } from './utils/previewMode';
import { shouldDisplay } from './utils/should-display';
import { buildTrackFn } from './utils/track';
import { getBrowserInfo } from './utils/ua';
import { getPathName, getQueryStringObject } from './utils/window';
import {AdrollWindow} from "./common/adroll-window";

declare var window: AdrollWindow;

dotenv.config();

export let track = () => {};

new Promise<void>((resolve, reject) => {
  const query = getQueryStringObject();
  if (query['adroll-prec-preview-mode'] !== '1') {
    // if not in preview mode, resolve immediately
    return resolve();
  }

  // otherwise, load preview settings from s3
  return getS3PreviewConfig(window.adroll_adv_id).then(data => {
    window['adrollRolliePreviewMode'] = 'websiteIframe';
    window['adrollRollieCustomizationPreview'] = data.adrollRollieCustomizationPreview;
    window['adrollRolliePreferencesPreview'] = data.adrollRolliePreferencesPreview;
    // also register a callback function to run trigger the preview to display after it is done loading
    window['adrollRollieContainerMounted'] = function() {
      window.postMessage({
        adrollRollieCustomizationPreview: data.adrollRollieCustomizationPreview,
        adrollRolliePreferencesPreview: data.adrollRolliePreferencesPreview
      }, '*');
      window.setTimeout(function() {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    };
    // with globals set, widget will continue loading in preview mode;
    resolve();
  });
})

.then(() => {
  const productStreamEid =
    window.localStorage.getItem(LOCAL_STORAGE_PRODUCT_STREAM_KEY) ||
    process.env.REACT_APP_DEFAULT_PRODUCT_STREAM_KEY;
  window.localStorage.removeItem(LOCAL_STORAGE_PRODUCT_STREAM_KEY);

  const {
    // @ts-ignore
    adroll_adv_id: adroll_adv_id = process.env.REACT_APP_DEFAULT_ADVERTISABLE_EID,
    // @ts-ignore
    adroll_pix_id,
  } = window;

  const previewModeType = getPreviewModeType();
  const { browserName, deviceType, version }: BrowserInfo = getBrowserInfo(previewModeType);

  const config = {
    advertisableEid: adroll_adv_id,
    // @ts-ignore
    pixelId: adroll_pix_id,
    browserName,
    deviceType,
    version,
    productStreamEid,
    inline: false,
    previewModeType
  };

  (async () => {
    const displayCheck = await shouldDisplay(adroll_adv_id, config);
    if (!displayCheck) {
      return;
    }

    const { appConfiguration } = displayCheck;

    const newVal = placeRoot(
      appConfiguration.ecommerce_platform,
      getPathName(),
      appConfiguration,
      previewModeType,
      config
    );

    if (!newVal) {
      return;
    }

    const isInline = (config.inline = newVal.inline); //assignment

    track = buildTrackFn(
      browserName,
      deviceType,
      appConfiguration.personalization.template,
      isInline
    );

    const extraProps = isInline ? {
      inlineElement: newVal.onsiteElement,
      inline: newVal.inline,
      referenceNode: newVal.referenceNode,
      insertBeforeElem: newVal.insertBeforeElem,
      appConfiguration
    } : {};

    ReactDOM.render(
      <Dimensions
        {...extraProps}
        render={dimensions => (
          <App
            theme={MEDIA_QUERY_THEMES.DESKTOP}
            config={config}
            dimensions={dimensions}
            appConfiguration={appConfiguration}
            previewModeType={previewModeType}
          />
        )}
      />,
      newVal.onsiteElement
    );
  })();
});
