import { PlacementType } from '../constants';

export const placements = {
  shopify: {
    footer: {
      selectors: [
        '#shopify-section-footer',
        '#shopify-section-static-footer',
        '#shopify-section-static-footer',
        '#shopify-section-site-footer',
        '#shopify-section-general-footer',
        '#footer',
        'footer',
        '.footer',
        '.copyright',
        '.related-and-upsells',
      ],
      verify: footerElem => {
        if (footerElem && footerElem.parentElement && footerElem.parentElement.tagName === 'FOOTER') {
          return footerElem.parentElement;
        }
        return footerElem;
      },
    },
    main: {
      selectors: [
        '#main',
        '#Main',
        '#MainContent',
        'main',
        '[role="main"]',
        '#content',
        '#container',
        '.site-main',
        '.main-content',
        '.content',
        'body',
      ],
      children: {
        selectors: ['.shopify-section'],
      },
    },
  },
  bigcommerce: {
    footer: {
      selectors: [
        'footer',
        '#footer',
        '.site-footer',
        '.main-footer',
        '.footer',
        '.footer-wrapper',
        '.footer-info',
        '.copyright',
      ],
    },
    main: {
      selectors: ['main', 'main-content', '.main', '#main', '.container', '.body', 'body'],
      children: {
        selectors: ['section'],
      },
    },
  },
  magento: {
    footer: {
      selectors: [
        'footer',
        '#footer',
        '.footer',
        '.footer-container',
        '.footer-static-container',
        '.footer-wrapper',
        '.wrapper-footer',
        '[class^="footer-"]', // should this be class~
      ],
      verify: footerElem => {
        let curr = footerElem;
        while (curr && curr.parentElement) {
          const parentClassName = curr.parentElement.className;
          if (parentClassName && parentClassName.toLowerCase().indexOf('footer') !== -1) {
            curr = curr.parentElement;
          } else {
            break;
          }
        }
        return curr;
      },
    },
    main: {
      selectors: [
        '#main',
        '#Main',
        '#MainContent',
        'main',
        '.container',
        '.main-container',
        '#content',
        '#container',
        '.site-main',
        '.main-content',
        '.content',
        'body',
      ],
      children: {
        selectors: ['div'],
      },
    },
  },
  magento1: {},
  magento2: {
    footer: {
      selectors: [
        'footer',
        '#footer',
        '.footer',
        '.footer-container',
        '.footer-static-container',
        '.footer-wrapper',
        '.wrapper-footer',
        '[class^="footer-"]', // should this be class~?
      ],
      verify: footerElem => {
        let curr = footerElem;
        while (curr && curr.parentElement) {
          const parentClassName = curr.parentElement.className;
          if (parentClassName && parentClassName.toLowerCase().indexOf('footer') !== -1) {
            curr = curr.parentElement;
          } else {
            break;
          }
        }
        return curr;
      },
    },
    main: {
      selectors: [
        '#main',
        '#Main',
        '#MainContent',
        'main',
        '.container',
        '.main-container',
        '#content',
        '#container',
        '.site-main',
        '.main-content',
        '.content',
        'body',
      ],
      children: {
        selectors: ['div'],
      },
    },
  },
  prestashop: {
    footer: {
      selectors: [
        '#footer',
        '.footer',
        '.footer-container',
        '.footer-static-container',
        '.footer-wrapper',
        '.wrapper-footer',
        'footer',
      ],
      verify: footerElem => {
        if (footerElem && footerElem.parentElement && footerElem.parentElement.tagName === 'FOOTER') {
          return footerElem.parentElement;
        }
        return footerElem;
      },
    },
    main: {
      selectors: [
        '#main',
        '#Main',
        '#MainContent',
        'main',
        '.container',
        '.main-container',
        '#content',
        '#container',
        '.site-main',
        '.main-content',
        '.content',
        'body',
      ],
      children: {
        selectors: ['div'],
      },
    },
  },
  woocommerce: {
    footer: {
      selectors: [
        '#footer',
        '.footer',
        '.footer-container',
        '.footer-static-container',
        '.footer-wrapper',
        '.wrapper-footer',
        'footer',
      ],
    },
    main: {
      selectors: [
        '#main',
        '#Main',
        '#MainContent',
        'main',
        '.container',
        '.main-container',
        '#content',
        '#container',
        '.site-main',
        '.main-content',
        '.content',
        'body',
      ],
      children: {
        selectors: ['div'],
      },
    },
  },
};
placements.magento1 = placements.magento2;

export const defaultPlacementThemes = {
  fixedfooter: [
    {
      path: '*',
      type: PlacementType.FIXED,
    },
  ],
  abovefooter: [
    // Name of the theme
    {
      path: '*',
      type: PlacementType.INLINE,
      placement: placements.shopify.footer,
      placementIndex: -2,
    },
  ],
  productfooter: [
    // Name of the theme
    {
      path: '/product',
      type: PlacementType.INLINE,
      placement: placements.shopify.footer,
      placementIndex: -2,
    },
    {
      path: '*',
      type: PlacementType.NONE,
      //do nothing
    },
  ],
  atlantic: [
    // Random name for a theme
    {
      path: '/product',
      type: PlacementType.INLINE,
      placement: placements.shopify.main,
      placementIndex: 2,
    },
    {
      path: '/collections',
      type: PlacementType.INLINE,
      placement: placements.shopify.footer,
      placementIndex: 1,
    },
    {
      path: '*',
      type: PlacementType.NONE,
    },
  ],
};

export const placementThemes = {
  shopify: {
    fixedfooter: [
      {
        path: '*',
        type: PlacementType.FIXED,
      },
    ],
    abovefooter: [
      // Name of the theme
      {
        path: '*',
        type: PlacementType.INLINE,
        placement: placements.shopify.footer,
        placementIndex: -2,
      },
    ],
    productfooter: [
      // Name of the theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.shopify.footer,
        placementIndex: -2,
      },
      {
        path: '*',
        type: PlacementType.NONE,
        //do nothing
      },
    ],
    atlantic: [
      // Random name for a theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.shopify.main,
        placementIndex: 2,
      },
      {
        path: '/collections',
        type: PlacementType.INLINE,
        placement: placements.shopify.footer,
        placementIndex: 1,
      },
      {
        path: '*',
        type: PlacementType.NONE,
      },
    ],
  },
  bigcommerce: {
    fixedfooter: [
      {
        path: '*',
        type: PlacementType.FIXED,
      },
    ],
    abovefooter: [
      // Name of the theme
      {
        path: '*',
        type: PlacementType.INLINE,
        placement: placements.bigcommerce.footer,
        placementIndex: -1, // for the footer -ve value indicates before and +ve means after
      },
    ],

    atlantic: [
      // Random name for a theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.bigcommerce.main,
        placementIndex: 2,
      },
      {
        path: '/collections',
        type: PlacementType.INLINE,
        placement: placements.bigcommerce.footer,
        placementIndex: -1,
      },
      {
        path: '*',
        type: PlacementType.NONE,
      },
    ],
  },
  magento1: {
    fixedfooter: [
      {
        path: '*',
        type: PlacementType.FIXED,
      },
    ],
    abovefooter: [
      // Name of the theme
      {
        path: '*',
        type: PlacementType.INLINE,
        placement: placements.magento.footer,
        placementIndex: -2,
      },
    ],
    productfooter: [
      // Name of the theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.magento.footer,
        placementIndex: -2,
      },
      {
        path: '*',
        type: PlacementType.NONE,
        //do nothing
      },
    ],
    atlantic: [
      // Random name for a theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.magento.main,
        placementIndex: 2,
      },
      {
        path: '/collections',
        type: PlacementType.INLINE,
        placement: placements.magento.footer,
        placementIndex: 1,
      },
      {
        path: '*',
        type: PlacementType.NONE,
      },
    ],
  },
  prestashop: {
    fixedfooter: [
      {
        path: '*',
        type: PlacementType.FIXED,
      },
    ],
    abovefooter: [
      // Name of the theme
      {
        path: '*',
        type: PlacementType.INLINE,
        placement: placements.prestashop.footer,
        placementIndex: -2,
      },
    ],
    productfooter: [
      // Name of the theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.prestashop.footer,
        placementIndex: -2,
      },
      {
        path: '*',
        type: PlacementType.NONE,
        //do nothing
      },
    ],
    atlantic: [
      // Random name for a theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.prestashop.main,
        placementIndex: 2,
      },
      {
        path: '/collections',
        type: PlacementType.INLINE,
        placement: placements.prestashop.footer,
        placementIndex: 1,
      },
      {
        path: '*',
        type: PlacementType.NONE,
      },
    ],
  },
  woocommerce: {
    fixedfooter: [
      {
        path: '*',
        type: PlacementType.FIXED,
      },
    ],
    abovefooter: [
      // Name of the theme
      {
        path: '*',
        type: PlacementType.INLINE,
        placement: placements.woocommerce.footer,
        placementIndex: -2,
      },
    ],
    productfooter: [
      // Name of the theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.woocommerce.footer,
        placementIndex: -2,
      },
      {
        path: '*',
        type: PlacementType.NONE,
        //do nothing
      },
    ],
    atlantic: [
      // Random name for a theme
      {
        path: '/product',
        type: PlacementType.INLINE,
        placement: placements.woocommerce.main,
        placementIndex: 2,
      },
      {
        path: '/collections',
        type: PlacementType.INLINE,
        placement: placements.woocommerce.footer,
        placementIndex: 1,
      },
      {
        path: '*',
        type: PlacementType.NONE,
      },
    ],
  },
};

placementThemes.magento2 = placementThemes.magento1;
placementThemes.magento = placementThemes.magento1;

export const getAppPlacementTheme = (personalization, config) => {
  return (
    (personalization.placements && personalization.placements[config.deviceType]) || personalization.defaultPlacement
  );
};
