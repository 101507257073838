import promise from 'es6-promise';

export const getCart = () => {
  return new promise.Promise(function(resolve, reject) {
    resolve(getCartData());
  });
};

export const getCartData = () => {
  return {
    cartUrl: '/checkout/cart/',
  };
};
