import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShoppingCartIcon extends Component {
  render() {
    return (
      <svg
        className={'adroll-rollie-cart-icon'}
        version={'1.1'}
        x={'0px'}
        y={'0px'}
        viewBox={'0 0 522 478.5'}
        style={this.props.style}
      >
        <g>
          <path
            d={
              'M410.4,340.9c-38,0-68.8,30.9-68.8,68.8s30.9,68.8,68.8,68.8s68.8-30.9,68.8-68.8C479.2,371.8,448.3,340.9,410.4,340.9z M430.9,409.8c0,11.3-9.2,20.5-20.5,20.5s-20.5-9.2-20.5-20.5s9.2-20.5,20.5-20.5S430.9,398.4,430.9,409.8z'
            }
          />
          <path
            d={
              'M516.9,96.1c-4.6-5.9-11.5-9.3-19-9.3H127.2l-16.3-68.3C108.3,7.6,98.6,0,87.4,0H24.2l0,0C17.7,0,11.7,2.5,7.1,7.1 C2.5,11.6,0,17.7,0,24.2c0,13.3,10.8,24.1,24.2,24.1h44.1l61.3,256.6c2.6,10.9,12.3,18.5,23.5,18.5h298.1 c11.1,0,20.8-7.5,23.4-18.3l46.6-188.3C523.1,109.5,521.5,102,516.9,96.1z M467,135.1l-34.6,140H172.2l-33.5-140L467,135.1 L467,135.1z'
            }
          />
          <path
            d={
              'M178.6,340.9c-38,0-68.8,30.9-68.8,68.8s30.9,68.8,68.8,68.8s68.8-30.9,68.8-68.8C247.4,371.8,216.6,340.9,178.6,340.9z M178.6,430.3c-11.3,0-20.5-9.2-20.5-20.5s9.2-20.5,20.5-20.5s20.5,9.2,20.5,20.5S190,430.3,178.6,430.3z'
            }
          />
        </g>
      </svg>
    );
  }
}

ShoppingCartIcon.propTypes = {
  style: PropTypes.object.isRequired,
};

export default ShoppingCartIcon;
