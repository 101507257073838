import { PREVIEW_MODE_TYPE_WIDGET_ONLY } from './constants';
import later from './later';
import { trackUEV } from './uev';

export const navigate = (url: string) => {
  window.location.href = url;
};

export const scrollIntoView = (domElementId: any) => {
  try {
    // @ts-ignore
    document
      .getElementById(domElementId)
      // @ts-ignore
      .scrollIntoView(false, { behavior: 'smooth', inline: 'center' });
  } catch (e) {
    // failed to scroll into view, ignore
  }
};

export const dispatchResizeEvent = () => {
  // This is needed because of a "bug" with this carousel. Without this, the carousel will fail to render
  // correctly when returning to a tab that was previously visited.
  // https://github.com/FormidableLabs/nuka-carousel/issues/103
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 0);
};

export const addEventListener = (type: string, fn: any) => {
  window.addEventListener(type, fn);
};

export const removeEventListener = (type: string, fn: any) => {
  window.removeEventListener(type, fn);
};

export const dispatchWindowEvent = (eventName: string, timeout: number) => {
  return later(timeout, () => window.dispatchEvent(new Event(eventName)));
};

const isPreview = () => window.location.href.indexOf('preview') !== -1; //
const idKey = 'adroll-onsite-preview-iframe-link';
const getFromIframe = () => {
  if (
    isPreview() &&
    parent &&
    parent.document &&
    parent.document.getElementById(idKey) &&
    parent.document.getElementById(idKey).getAttribute('data-target')
  ) {
    return parent.document.getElementById(idKey).getAttribute('data-target');
  }
  return undefined;
};

export const getPathName = () => {
  const fromIframe = getFromIframe();
  if (!fromIframe) {
    return window.location.pathname;
  }
  const url = new URL(fromIframe);
  return url.pathname;
};

export const getHref = () => {
  const fromIframe = getFromIframe();
  if (!fromIframe) {
    return window.location.href;
  }
  const url = new URL(fromIframe);
  return url.href;
};

export const getAttributes = () => ({
  ...window,
});

export const getQueryStringObject = () => {
  // Get the window location query string and convert it into a nice object.
  const search = window.location.search.substring(1);
  if (!search) {
    return '';
  }
  return JSON.parse(
    `{"${decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`
  );
};

export const getTrackFn = previewModeType => {
  if (previewModeType === PREVIEW_MODE_TYPE_WIDGET_ONLY) {
    // Blackhole the track calls in previewMode to not flood the console while
    // debugging the builder
    return () => {};
  }
  // Checks if the pixel is placed or not.
  // @ts-ignore
  if (!window.__adroll) {
    return console.log;
  }
  // @ts-ignore
  if (window.adroll_version === '2.0') {
    // @ts-ignore
    return window.adroll.track;
  }
  return trackUEV;
};

export const calculateHeight = (refElement: Element) => {
  const parentHeight = getFixedNodeHight(refElement);

  const childrenHeight = Array.from(refElement.children).reduce((acc, elem) => {
    acc += getFixedNodeHight(elem);
    return acc;
  }, 0);

  return Math.max(parentHeight, childrenHeight);
};

const getFixedNodeHight = (element: Element) => {
  const style = window.getComputedStyle(element);
  if (!style || style.position !== 'fixed') {
    return 0;
  }
  const hStr = style.getPropertyValue('height'); // usually "0px"
  return parseInt(hStr.substring(0, hStr.length - 2), 10);
};
export const getNodeHeight = (refElement: Element) => refElement.clientHeight;
