import { default as cx } from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ATTRIBUTION_TRACKING } from 'utils/constants';
import { track } from '../../../index';
import { canAddToCart } from 'utils/isDnd';
import { navigate, scrollIntoView } from 'utils/window';

class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.domElementId = `t${props.tabId}_p${props.product.id}`;
  }

  handleClick = e => {
    const { idhash, variant_ids, url, source } = this.props.product;
    const trackData = {
      productId: idhash,
      variantId: variant_ids,
      source,
    };
    if (!canAddToCart(variant_ids, this.props.appConfiguration)) {
      track(ATTRIBUTION_TRACKING.ONSITE_PRODUCT_SELECT, trackData);
      // Don't navigate to the product page if in preview mode.
      if (!this.props.previewModeType) {
        navigate(url);
      }
    } else if (!this.props.selected) {
      track(ATTRIBUTION_TRACKING.ONSITE_PRODUCT_SELECT, trackData);
      this.props.setSelectedProductIndex(this.props.index);
      scrollIntoView(this.domElementId);
    } else {
      track(ATTRIBUTION_TRACKING.ONSITE_PRODUCT_DESELECT, trackData);
      this.props.setSelectedProductIndex(null);
    }
  };

  render() {
    const { product, selected } = this.props;
    const classNames = cx('adroll-rollie-mobile-product-card', {
      'adroll-rollie-mobile-product-card-selected': selected,
    });

    return (
      <div
        id={this.domElementId}
        productid={product.idhash}
        className={classNames}
        onClick={this.handleClick}
        source={product.source}
      >
        <img src={product['i/image']} alt={product.title} />
        <div
          className="adroll-rollie-product-card-price price-bottom-right"
          style={this.props.appConfiguration.personalization.mobile.product.price.styles}
        >
          {product.sale_price || product.price}
        </div>
      </div>
    );
  }
}

ProductCard.propTypes = {
  tabId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  setSelectedProductIndex: PropTypes.func.isRequired,
  previewModeType: PropTypes.string,
};

export default ProductCard;
