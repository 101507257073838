import { APP_STATE_KEY } from './constants';
import {
  getAppConfiguration,
  isCustomizationTestModeActive,
} from './customization';
import { logMessage } from './logger';
import { readFromSession } from './storage';
import {
  Config,
  CanDisplay,
  AppConfiguration,
  NoBuilderCustomization,
} from '../common/interfaces';
export async function shouldDisplay(
  advertisable_eid: string,
  config: Config
): Promise<CanDisplay | undefined> {
  const appConfiguration: AppConfiguration = await getAppConfiguration(
    advertisable_eid
  );
  if (!appConfiguration) {
    logMessage(
      `Onsite widget not displayed as we are not able to load App configuration`,
      `shouldDisplay`
    );
    return;
  }

  const canDisplayVal = {
    display: true,
    appConfiguration,
  };

  const { previewModeType } = config;
  if (previewModeType !== undefined || isCustomizationTestModeActive()) {
    logMessage(
      `Displaying Onsite widget as we are either in Preview / Test mode`,
      'shouldDisplay'
    );
    return canDisplayVal;
  }

  if (isForcedDisplay()) {
    logMessage(
      `Displaying Onsite widget due to forced display`,
      'shouldDisplay'
    );
    return canDisplayVal;
  }

  if (!browserCheck(appConfiguration.noBuilderCustomizations, config)) {
    /* No need to pass the configuration as invalid anyway */
    logMessage(
      `Onsite widget not displayed due to browser limitations`,
      'shouldDisplay'
    );
    return undefined;
  }

  if (appConfiguration.personalization.published === false) {
    // Prec compatibility: honor the "published" value if present.
    logMessage(
      'Onsite widget not displayed due to being unpublished',
      'shouldDisplay'
    )
    return undefined;
  }
  return canDisplayVal;
};

const isForcedDisplay = () => {
  const forceLocalObj = readFromSession(`${APP_STATE_KEY}_FORCE_LOCAL`);
  return forceLocalObj && forceLocalObj.display;
};

export const browserCheck = (
  noBuilderCustomizations: NoBuilderCustomization,
  config: Config
): boolean => {
  const { browserName, version, deviceType } = config;
  const versionVal = parseInt(version, 10);
  const {
    show: { browsers, devices },
  } = noBuilderCustomizations;
  const found =
    browsers.filter(
      b =>
        b.name === browserName &&
        versionVal >= b.minVersion &&
        versionVal <= b.maxVersion
    ).length > 0;
  return found && devices[deviceType];
};
