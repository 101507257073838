import * as Window from './window';

const getStyle = (logLevel: string): string => {
  switch (logLevel) {
    case 'INFO':
      return 'background: green; color: white; display: block;';
    case 'WARN':
      return 'background: orange; color: white; display: block;';
    case 'error':
      return 'background: red; color: white; display: block;';
    default:
      return 'background: white; color: black; display: block;';
  }
};

export const logMessage = (
  message: string,
  tagData: string | undefined,
  level: string = 'warn',
  scope: string = 'all',
  logToBackend: boolean = false
) => {
  const qObj = Window.getQueryStringObject();
  const logLevel = qObj['loglevel'] || level;
  const logScope = qObj['logscope'] || scope;

  const getStyleVal = getStyle(logLevel);
  const shouldLog =
    logScope !== undefined &&
    (logScope === 'all' ||
      (tagData !== undefined && tagData.indexOf(logScope) > -1));
  if (!shouldLog) {
    return;
  }
  const prefix = tagData ? `[${tagData}]:` : '';
  const NODE_ENV = process.env.NODE_ENV;
  if (NODE_ENV === 'test') {
    return;
  }
  console.log(`%c${prefix}${message}`, getStyleVal);
};
