import { getPreviewModeDeviceType } from './previewMode';
import { UAParser } from 'ua-parser-js';
import { BrowserInfo } from '../common/interfaces';

export function getBrowserInfo(previewModeType): BrowserInfo {
  const result = new UAParser(navigator.userAgent).getResult();

  return {
    browserName: String(result.browser.name).toLowerCase(),
    // device.type will return undefined on desktops, because desktop browsers don't include device info in UA strings
    deviceType: previewModeType ? getPreviewModeDeviceType() : result.device.type || 'desktop',
    version: result.browser.version,
  };
};
