import promise from 'es6-promise';
import { makeRequest } from './api';
import { safeJsonParse } from '../index';

export const getCartFromApi = () => {
  // Magento supports multiple websites configuration, so here we have a
  // simple logic that tries to first get the website-specific path as a prefix
  // when calling the Magento API.
  // For example: https://website.com/us/products/shirt
  // It will first try to access the api on /us/ and after on the website root.
  const urlsToTry = [];
  const firstPathPartEndLocation = window.location.pathname.indexOf('/', 1);
  if (firstPathPartEndLocation > -1) {
    urlsToTry.push(window.location.pathname.substring(0, firstPathPartEndLocation));
  }
  urlsToTry.push('');

  return new promise.Promise(function(resolve, reject) {
    // Tries in all urls prefixed in urlsToTry array (always 1 or 2 size), until
    // some returns a valid response or we reach the end of the array.
    function tryUrlPrefix(urlIndex) {
      if (urlIndex === urlsToTry.length) {
        reject('Error getting magento2 cart contents.');
        return;
      }
      makeRequest('GET', `${urlsToTry[urlIndex]}/customer/section/load/?sections=cart`)
        .then(resp => {
          const responseJson = safeJsonParse(resp);
          if (responseJson && responseJson.cart) {
            resolve(responseJson);
          } else {
            tryUrlPrefix(urlIndex + 1);
          }
        })
        .catch(err => {
          tryUrlPrefix(urlIndex + 1);
        });
    }
    tryUrlPrefix(0);
  });
};

export const getCart = () => {
  return getCartFromApi().then(apiResponse => {
    return getCartData(apiResponse);
  });
};

export const getCartData = apiResponse => {
  const cartData = [];
  if (apiResponse && apiResponse.cart && apiResponse.cart.items) {
    apiResponse.cart.items.forEach(item => {
      if (item.product_id) {
        cartData.push(item.product_id);
      }
    });
  }
  return {
    items: cartData,
    itemsCount: getCartCount(apiResponse),
    cartUrl: '/checkout/cart/',
  };
};

export const getCartCount = apiResponse => {
  if (apiResponse && apiResponse.cart && apiResponse.cart.summary_count) {
    return apiResponse.cart.summary_count;
  }
  return 0;
};
