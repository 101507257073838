import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { default as cx } from 'classnames';

import { track } from '../../../index';
import { trackCurrentlyVisibleProducts } from 'utils';
import ProductCard from 'components/MobileRollie/ProductCard';
import { ATTRIBUTION_TRACKING } from 'utils/constants';

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = { trackedSwipe: false };
  }

  handleTouchMove = e => {
    // If users scroll away from a selected product, we un-select it automatically
    this.props.setSelectedProductIndex(null);
    if (!this.state.trackedSwipe) {
      track(ATTRIBUTION_TRACKING.ONSITE_CAROUSEL_SWIPE, {});
      this.setState({ trackedSwipe: true });
    }
  };

  handleTouchEnd = e => {
    // only track visible products if the user swiped (scrolled to view different products)
    if (this.state.trackedSwipe) {
      trackCurrentlyVisibleProducts(track, ATTRIBUTION_TRACKING.ONSITE_CAROUSEL_SWIPE);
      this.setState({ trackedSwipe: false });
    }
  };

  render() {
    const classNames = cx('adroll-rollie-mobile-carousel', {
      'adroll-rollie-mobile-carousel-product-selected': this.props.selectedProductIndex !== null,
      'adroll-rollie-carousel-hidden': !this.props.selected,
    });

    return (
      <div className={classNames} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
        {this.props.products.map((product, index) => {
          const selected = this.props.selectedProductIndex === index;
          return (
            <ProductCard
              key={product.id}
              tabId={this.props.tabId}
              index={index}
              product={product}
              selected={selected}
              appConfiguration={this.props.appConfiguration}
              setSelectedProductIndex={this.props.setSelectedProductIndex}
              previewModeType={this.props.previewModeType}
            />
          );
        })}
      </div>
    );
  }
}

Carousel.propTypes = {
  tabId: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  selected: PropTypes.bool.isRequired,
  selectedProductIndex: PropTypes.number,
  setSelectedProductIndex: PropTypes.func.isRequired,
  dimensions: PropTypes.object.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
};

export default Carousel;
