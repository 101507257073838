import { default as cx } from 'classnames';
import PoweredByAdroll from 'components/common/WaterMark';
import HeaderMenu from 'components/WebRollie/HeaderMenu';
import MinimizeButton from 'components/WebRollie/MinimizeButton';
import ShoppingCart from 'components/WebRollie/ShoppingCart';
import ShoppingCartContainer from 'containers/ShoppingCartContainer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { showWaterMark } from 'utils/showWaterMark';

class Header extends Component {
  render() {
    const classNames = cx({
      'adroll-rollie-web-header': true,
      'adroll-rollie-web-header-not-inline': !this.props.inline,
    });

    const styles = !this.props.inline
      ? this.props.appConfiguration.personalization.header.styles
      : {
          ...this.props.appConfiguration.personalization.header.styles,
          backgroundColor: '',
          marginTop: '1em',
        };

    const { appConfiguration, inline } = this.props;

    return (
      <div className={classNames} style={styles}>
        <HeaderMenu
          tabs={this.props.tabs}
          customTabsProducts={this.props.customTabsProducts}
          selectedTab={this.props.selectedTab}
          setSelectedTab={this.props.setSelectedTab}
          appConfiguration={appConfiguration}
          inline={this.props.inline}
        />
        {!inline && showWaterMark(appConfiguration) && <PoweredByAdroll inline={inline} />}

        {!this.props.inline && (
          <>
            <ShoppingCartContainer
              renderWhenEmpty={true}
              appConfiguration={appConfiguration}
              shoppingCartComponent={ShoppingCart}
              cartData={this.props.cartData}
              previewModeType={this.props.previewModeType}
            />

            <MinimizeButton onClick={this.props.toggleViewState} appConfiguration={this.props.appConfiguration} />
          </>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  tabs: PropTypes.array.isRequired,
  customTabsProducts: PropTypes.object.isRequired,
  selectedTab: PropTypes.object.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  toggleViewState: PropTypes.func.isRequired,
  appConfiguration: PropTypes.object.isRequired,
  cartData: PropTypes.object,
  config: PropTypes.object.isRequired,
  previewModeType: PropTypes.string,
  inline: PropTypes.bool.isRequired,
};

export default Header;
